<template>
    <div class="tab-content">
        <LoadingSpinner :showLoading="!portfolioLoaded" style="width: 100%; height: 100%" />
        <template v-if='portfolioLoaded'>
            <div class="p-grid">
                <div class="left-col p-col-12 p-md-8">
                    <PerformanceChart
                        :portfolioReturns="percentReturnSeries"
                        :returnDates="returnDateSeries"
                        :comparisonReturns="comparisonReturnSeries"
                        :isCryptoAccount="isCryptoAccount"
                        :showInfoIcon="false"
                        :viewingOwnPortfolio="viewingOwnPortfolio"
                    />
                </div>
                <div class="right-col p-col-12 p-md-4">
                    <AllocationChart :sectorLabels="sectorLabels" :sectorValues="sectorValues" :assetLabels="assetLabels" :assetValues="assetValues" :showInfoIcon="false" 
                    :viewingOwnPortfolio="viewingOwnPortfolio" :holdingLabels="holdingLabels" :holdingValues="holdingValues" v-if="!isCryptoAccount"/>
                </div>
                <div class="card overview-table p-col-12">
                    <div class="table-header-container">
                        <div class="holdings-wrapper">
                            <h2 class="table-header">Holdings</h2>
                        </div>
                    </div>
                    <HoldingsTable :holdings="holdings" :selectedAccount="selectedAccountData" @update-position="openPositionInputModal"/>
                </div>
            </div>
        </template>
    </div>
    <PositionInputModal ref='positionInputModal'/>
</template>

<script>
import LoadingSpinner from '../../common/LoadingSpinner.vue';
import AllocationChart from '../overview/AllocationChart.vue';
import HoldingsTable from '../overview/HoldingsTable.vue';
import PerformanceChart from '../overview/PerformanceChart.vue';
import PositionInputModal from '../modal/PositionInputModal.vue';

import DataService from '../../../service/DataService';
import PortfolioService from '../../../service/PortfolioService';
import SecurityFormatter from '../../../common/SecurityFormatter';
import UserUtils from '../../../utilities/UserUtils';

import {CRYPTO_ACCOUNT_TYPE} from '../../../common/constants';
import format from 'date-fns/format';
import moment from 'moment';

export default {
    name: 'Overview',
    components: {
        LoadingSpinner, AllocationChart, HoldingsTable, PerformanceChart, PositionInputModal
    },

    props: {
        selectedAccountData: {
            type: Object,
            required: true
        },

        viewingOwnPortfolio: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            holdingsLoaded: false,
            allocationLoaded: false,
            perfLoaded: false,
            totalReturnPercent: null,
            percentReturnSeries: [],
            returnDateSeries: [],
            comparisonReturnSeries: [],
            sectorLabels: [],
            sectorValues: [],
            assetLabels: [],
            assetValues: [],
            holdingLabels: [],
            holdingValues: [],
            holdings: [],
            returnStartDate: null,
            returnEndDate: null,
            comparisonSymbol: 'SPY',
            errorLoading: false,
            quoteFetcher: null
        }
    },
    computed: {
        portfolioLoaded() {
            return this.holdingsLoaded && this.allocationLoaded && this.perfLoaded;
        },

        isCryptoAccount() {
            return this.selectedAccountData.accountTypeId === CRYPTO_ACCOUNT_TYPE['ID'];
        },
    },

    mounted() {
        this.totalReturnPercent = this.selectedAccountData.cumReturn * 100;

        if (!this.isCryptoAccount) {
            this.getSharedAccountAllocationData(this.selectedAccountData.investmentAccountId);
        }

        this.getSharedAccountHoldingsData(this.selectedAccountData.investmentAccountId);
        this.getSharedAccountPerformance(this.selectedAccountData.investmentAccountId);
    },
    unmounted() {
        this.stopPollingPriceChange();
    },

    methods: {
        openPositionInputModal(data) {
            
            let inputData = {};

            if (UserUtils.isUser(this.selectedAccountData.userId)) {
                inputData['account'] = this.selectedAccountData;
            }
            inputData['fromUserProfile'] = true;
            inputData['fromGroup'] = false;
            inputData['security'] = (data.security ? data.security : data.fund);
            inputData['quantity'] = data.quantity;
            inputData['']
           
            this.$refs.positionInputModal.open(inputData);
        },

        getSharedAccountAllocationData(accountId) {
            PortfolioService.getSharedAccountBreakdown(accountId).then(allocationData=> {
                if (allocationData.status === 200) {
                    this.getDataFromSharedAccountAllocation(allocationData.data);
                }
                this.allocationLoaded = true;
            });
            // console.log(allocationData);

            
        },

        getDataFromSharedAccountAllocation(allocationData) {
            this.sectorLabels = [];
            this.sectorValues = [];
            this.assetLabels = [];
            this.assetValues = [];

            if (allocationData.status === 'error') {
                return;
            }

            const sectorData = allocationData.sectorMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });
            const assetData = allocationData.classMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });

            const labelValueGenerator = (data, sectorOrAsset) => {
                let formattedCategory = '';
                const splitCategory = data.category.split(' ');
                const formattedWords = splitCategory.map((word) => {
                    return word.replace(word[0], word[0].toUpperCase());
                });

                if (formattedWords.length > 1) {
                    formattedCategory = formattedWords.join(' ');
                } else {
                    formattedCategory = formattedWords[0];
                }

                if (sectorOrAsset === 'sector') {
                    this.sectorLabels.push(formattedCategory);
                    this.sectorValues.push((data.value * 100).toFixed(2));
                } else if (sectorOrAsset === 'asset') {
                    this.assetLabels.push(formattedCategory);
                    this.assetValues.push((data.value * 100).toFixed(2));
                }
            };

            sectorData.forEach((data) => {
                labelValueGenerator(data, 'sector');
            });

            assetData.forEach((data) => {
                labelValueGenerator(data, 'asset');
            });
        },

        getSharedAccountHoldingsData(accountId) {
            PortfolioService.getSharedAccountHoldings(accountId).then(assetPositionData=>{
                if (assetPositionData.status === 200) {
                    this.getDataFromSharedAccountHoldings(assetPositionData.data);
                }
                this.holdingsLoaded = true;
            });
            // console.log(assetPositionData);

           
        },

        getDataFromSharedAccountHoldings(assetPositionData) {
            if (assetPositionData.status === 'error') {
                return;
            }

            const equityPositions = assetPositionData.holdings.equityPositions;
            const fundPositions = assetPositionData.holdings.fundPositions;
            const derivativePositions = assetPositionData.holdings.derivativePositions;
            const fixedIncomePositions = assetPositionData.holdings.fixedIncomePositions;
            const otherPositions = assetPositionData.holdings.otherAssetPositions;

            const publicHoldingsArray = [];

            if (equityPositions.length > 0) {
                equityPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fundPositions.length > 0) {
                fundPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (derivativePositions.length > 0) {
                derivativePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fixedIncomePositions.length > 0) {
                fixedIncomePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (otherPositions.length > 0) {
                otherPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            const orderedHoldingsArray = publicHoldingsArray.sort((a, b) => {
                return b.weight - a.weight;
            });

            this.holdings = orderedHoldingsArray;

            this.holdingLabels = [];
            this.holdingValues = [];

            let cumulativeWeight = 0;

            this.holdings.forEach((holding) => {
                const weight = holding.weight * 100;
                this.holdingValues.push(weight);
                cumulativeWeight += weight;

                const ticker = holding.otherAsset?.proxySymbol || holding.otherAsset?.symbol || holding.derivative?.symbol || holding.securityListing?.symbol || holding.fund?.symbol;
                this.holdingLabels.push(ticker);
            });

            this.holdingValues.push(100 - cumulativeWeight);
            this.holdingLabels.push('Other');

            this.getSnapQuotes();
            this.startPollingPriceChange();
        },

        getSharedAccountPerformance(accountId) {
            PortfolioService.getSharedAccountPerformanceData(accountId).then(portfolioPerformanceSeries=>{
                if (portfolioPerformanceSeries.status === 200) {
                    this.getDataFromSharedAccountPerformance(portfolioPerformanceSeries.data);
                    if( this.returnStartDate && this.returnEndDate) {
                        // interval = 0 as its not used, intervalType = 'Y' so we get eod data instead of interday values
                         this.getComparisonPerformance(this.comparisonSymbol, 0, 'Y', format(new Date(this.returnStartDate), 'yyyy-MM-dd'), format(new Date(this.returnEndDate), 'yyyy-MM-dd'));
                    }
                    else {
                        this.perfLoaded = true;
                    }
                   
                
                }
            });
            // console.log(portfolioPerformanceSeries);

            
        },

        getDataFromSharedAccountPerformance(portfolioReturnData) {
            // console.log(portfolioReturnData);

            this.percentReturnSeries = [];
            this.returnDateSeries = [];

            if (portfolioReturnData.status === 'error') {
                //this.$toast.add({ severity: 'error', summary: 'Error getting portfolio performance data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
                return;
            }

            const portfolioPerformanceSeries = portfolioReturnData.dataPoints;
            // need to get out the zero values to make the chart look half normal

            if (portfolioPerformanceSeries.length > 0) {

                const mostRecentPortfolioData = portfolioPerformanceSeries[portfolioPerformanceSeries.length - 1];
                const startingPortfolioData = portfolioPerformanceSeries[0];

                this.returnStartDate = startingPortfolioData.date;
                this.returnEndDate = mostRecentPortfolioData.date;

                portfolioPerformanceSeries.forEach((data) => {
                    this.percentReturnSeries.push(data.value * 100);

                    const date = moment(data.date);
                   
                    const formattedDate = date.format("MM/DD");
                    this.returnDateSeries.push(formattedDate);
                });
            }
        },

        getComparisonPerformance(symbol, interval, intervalType, startDate, endDate) {
            DataService.getPriceHistory(symbol, interval, intervalType, startDate, endDate).then(comparisonPerformance=>{
                 if (comparisonPerformance.status === 200) {
                    this.getDataFromComparisonPerformance(comparisonPerformance.data.results);
                }
                this.perfLoaded = true;
            })
            // console.log(comparisonPerformance);

           
        },

        dateExistsInComparison(aDate, comparisonArray) {
            //"date":"2022-11-18",
           for( var i = 0; i < comparisonArray.length; ++i ) {
                let data = comparisonArray[i];
                const date = moment(data.date);
                   
                const formattedDate = date.format("MM/DD");
                if( aDate === formattedDate ){
                    return true;
                }
            }
            console.log(aDate +" doesn't exist in comparison data...removing");
            return false;
        },

        getDataFromComparisonPerformance(comparisonPerformance) {
            // console.log(comparisonPerformance);

            this.comparisonReturnSeries = [];

            const comparisonArray = comparisonPerformance.history[0].eoddata
            // The returns may have data points that don't exist in the comparison data series.
            // we let the comparison data series dictate if the datapoint should exist on the chart by removing
            // any data points that don't have a corresponding data point in the comparison data series.
            let toRemove = [];
            for( var i = 0; i < this.returnDateSeries.length; ++i ) {
                if( !this.dateExistsInComparison(this.returnDateSeries[i], comparisonArray) ) {
                    toRemove.push(i);
                }
            }

            for( var j = 0; j < toRemove.length; ++j ) {
                this.returnDateSeries.splice(toRemove[j]-j, 1);
                this.percentReturnSeries.splice(toRemove[j]-j, 1);
            }

            //console.log("return Date series length after adj: "+ this.returnDateSeries.length);
            //console.log("precent return series length after adj: "+ this.percentReturnSeries.length);

            const comparisonStartingValue = comparisonArray[comparisonArray.length - 1].close;

            comparisonArray.forEach((data) => {
                const comparisonReturn = (data.close / comparisonStartingValue - 1) * 100;
                this.comparisonReturnSeries.unshift(comparisonReturn);
            });
        },

         startPollingPriceChange() {
			if( this.quoteFetcher ){
				clearInterval(this.quoteFetcher);
			}
			this.quoteFetcher = setInterval(()=> {
				this.getSnapQuotes();
			}, 10000);
		},
		stopPollingPriceChange() {
			if( this.quoteFetcher ){
				clearInterval(this.quoteFetcher);
			}
		},

        createSnapQuoteSymbols() {
            let symbols = [];
            this.holdings.forEach( h => {
                let symbol = this.createSnapQuoteSymbol(h);
                if( symbol ) {
                    symbols.push(symbol);
                }
            });

            return symbols;
            
        },

        createSnapQuoteSymbol(h) {
            let symbol = null;
            let exSymbol = null;
            if( h.otherAsset ) {
                symbol =  h.otherAsset?.symbol ;
                exSymbol = h.otherAsset?.exchangeSymbol;

            
            } else if( h.securityListing ) {
                symbol =  h.securityListing?.symbol 
                exSymbol = h.securityListing?.exchangeSymbol;

                
                
            }
           
            if( symbol ) {
                return symbol+SecurityFormatter.getQMExchangeSymbolSuffix(exSymbol);
            }
        },


        async getSnapQuotes() {
            if( this.holdings.length > 0 ) {
                await DataService.getSnapQuotes(this.createSnapQuoteSymbols()).then(resp => {
                        
                    let quoteData = resp.data.results.quote;
                    if( quoteData) {
                        this.holdings.forEach( el => {
                            quoteData.forEach( q => {
                              if (q.pricedata) {
                                if( q.key.symbol === this.createSnapQuoteSymbol(el)) {
                                   
                                    el['changepercent'] = q.pricedata.changepercent;
                                    el['open'] = q.pricedata.open;
                                    el['close'] = q.pricedata.close;
                                    el['last'] = q.pricedata.last;
                                    el['high'] = q.pricedata.high;
                                    el['low'] = q.pricedata.low;
                                    el['bid'] = q.pricedata.bid;
                                    el['ask'] = q.pricedata.ask;
                                    el['tradevolume'] = q.pricedata.tradevolume;
                                    el['prevclose'] = q.pricedata.prevclose;
                                    el['tick'] = q.pricedata.tick;
                                    if( q.fundamental && q.fundamental.dividend) {
                                        el['yield'] = q.fundamental.dividend.yield;
                                    }
                                    else {
                                        el['yield'] = '-';
                                    }
                                    
                                }
                              }
                            });
                        });
                    }
                });
            }
        },
    }

}
</script>

<style scoped>
.overview-table {
    width: 100%;
    margin-bottom: 48px;
}

.table-header {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    margin-bottom: 0;
    margin-right: 4px;
}

.table-header-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
}

.holdings-wrapper {
    display: flex;
    align-items: center;
}
</style>