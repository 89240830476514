<template>
    <div class='ff-search-card card'>
        <div class='card-row'>
            <span class='ff-card-header'>{{headerText}}</span>
            <SelectButton class='ff-toggle-button' v-model="selectedOption" :options="options" />
        </div>
        <SearchBar ref='analystSearchBar' @search-input="searchInput" @loading="onLoading" :placeholderText="placeholderText" />
        <LoadingSpinner :showLoading="initLoading" minHeight="100vh"/>
        <infinite-scroll @infinite-scroll="loadMoreResults" message="" :noResult="noMoreSearchResults" :style='scrollStyle'>
            <UserListItem v-for="user in searchResults" :user="user" :key="user.userId">
                <template #actions v-if='user.userTypeId !== 2 && userUtils.isUser(analyst.userId)'>
                    <FollowButton :followTarget="user" :confirmUnfollow="false" :followVal="user.activityStats.following" v-if="!userUtils.isUser(user.userId)"/>
                </template>
            </UserListItem>
            <!-- <LoadingSpinner :showLoading="loading && !isSearching" minHeight='100px'/> -->
        </infinite-scroll>
    </div>
</template> 

<script>
import SearchBar from '../query/SearchBar.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue'; 
import SelectButton from 'primevue/selectbutton';
import UserListItem from '../list/UserListItem.vue';
import FollowButton from '../button/FollowButton.vue';

import UserService from '../../service/UserService';
import UserUtils from '../../utilities/UserUtils'
import StringUtils from '../../utilities/StringUtils';

import Mark from 'mark.js';

const FOLLOWERS_OPTION = 'Followers';
const FOLLOWING_OPTION = 'Following';
const SELECTED_OPTION_VAL = {
    [FOLLOWERS_OPTION]: {
        'headerText': 'Followers',
        'placeholderText': {
            [true]: 'Search your followers',
            [false]: 'Search their followers'
        }
    },
    [FOLLOWING_OPTION]: {
        'headerText': 'Following',
        'placeholderText': {
            [true]: "Search the people you're following",
            [false]: "Search the people they're following"
        }
    }
}

export default {
    name: 'FollowFolloweeSearch',
    components: {
        LoadingSpinner, SearchBar, InfiniteScroll, SelectButton, UserListItem, FollowButton
    },
    props: {
        isUser: {
            type: Boolean,
            required: true
        },
        analyst: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            //CONSTANTS
            FOLLOWERS_OPTION,

            placeholderText: '',
            selectedOption: FOLLOWERS_OPTION,
            options: [FOLLOWERS_OPTION, FOLLOWING_OPTION],
            headerText: '',
            loading: false,
            initLoading: false,
            currentText: '',
            searchResults: [],
            noMoreSearchResults: false,
            scrollStyle: "width: 100%; height: 500px;",

            stringUtils: StringUtils,
            userUtils: UserUtils
        }
    },

    watch: {
        selectedOption(val) {
            this.toggleLoader(val);
        }


    },

    mounted() {
        this.currentText = '';
        this.toggleLoader(FOLLOWERS_OPTION);
    },

    methods: {
        toggleLoader(option) {
            const obj = SELECTED_OPTION_VAL[option];

            this.headerText = obj.headerText;
            this.placeholderText = obj.placeholderText[this.isUser];
            this.initLoadAnalysts(); 
        },

        onLoading() {
            this.searchResults = [];
            this.initLoading = true;
            this.loading = false;
        },
        searchInput(val) {
            this.currentText = val;
            this.fetchAnalystsFromSearch(val, 10, 0);
        },

        initLoadAnalysts() {
            this.searchResults = [];
            this.initLoading = true;
            this.loading = false;
            this.fetchAnalystsFromSearch(this.currentText, 10, 0);
        },

        async loadMoreResults() {
            if (!this.loading) {
                if (!this.noMoreSearchResults) {
                    this.fetchAnalystsFromSearch(this.currentText, 10, this.searchResults.length);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchAnalystsFromSearch(query, limit, offset) {
            this.loading = true;

            if( this.selectedOption == FOLLOWERS_OPTION){
                UserService.queryFollowers(this.analyst.analystId, query, limit, offset).then(resp => {
                    this.initLoading = false;
                    this.loading = false;
                    //console.log(JSON.stringify(resp));
                    if( resp.data.status == "success" ){
                        this.handleSearchResults(query, resp);
                    }
                    else {
                        console.log("error doing follower search: "+ resp.data.message);
                    }
                });
            }
            else {
                 UserService.queryFollowing(this.analyst.analystId, query, limit, offset).then(resp => {
                    this.initLoading = false;
                    this.loading = false;
                    if( resp.data.status == "success" ){
                        this.handleSearchResults(query, resp);
                    }
                    else {
                        console.log("error doing following search: "+ resp.data.message);
                    }
                });
            }
        },

        handleSearchResults( query, resp ){

            const analysts = resp.data.results.analysts;

            if (analysts.length > 0) {
                const results = this.searchResults.concat(analysts);

                this.searchResults = results;

                this.noMoreSearchResults = false;

                // Highlighting of matches in results
                this.$nextTick(() => {
                    let instance = new Mark(document.querySelectorAll('.user-list-item-container .text-container'));

                    const regex = new RegExp(`\\b${query}|(?<=[@(])\\b${query}`, 'gi');

                    instance.unmark();
                    instance.markRegExp(regex, {
                        'className': 'search-match-highlight'
                    });
                })
            } else {
                this.noMoreSearchResults = true;
            }
            
        }

    }
}
</script>
<style scoped>
.ff-search-card {
    padding: 16px 0px 0px;
    margin-bottom: 0px;
}

.ff-card-header {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.card-row {
    display: flex;
    padding: 0px 16px 16px;
    align-items: center;
}

::v-deep(.search-bar-container) {
    flex: 1;
}

 ::v-deep(.ff-toggle-button) {
     margin-left: auto;
 }
 ::v-deep(.ff-toggle-button.disabled) {
	opacity: 0.4;
 }
 ::v-deep(.ff-toggle-button .p-button.active),
    ::v-deep(.ff-toggle-button .p-button:enabled:hover) {
        background: #32364E;
        color: #FFFFFF;
        
    }
	

	::v-deep(.ff-toggle-button .p-button:focus) {
		box-shadow: none;
	}

	
	::v-deep(.ff-toggle-button.p-selectbutton .p-button.p-highlight) {
		background: #32364E;
        color: #FFFFFF; 
		pointer-events: none;
		border-radius: 2rem;
		z-index: 2;
	}
	::v-deep(.ff-toggle-button.p-selectbutton .p-button ) {
		border-color: #32364E;
		padding-left: 20px;
		padding-right: 20px;
	}
	::v-deep(.ff-toggle-button.p-selectbutton .p-button:first-of-type ) {
		left: 20px;
	}
	::v-deep(.ff-toggle-button.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
		border-radius: 2rem 0 0 2rem;
        padding-right: 30px;
	}
		::v-deep(.ff-toggle-button.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
		border-radius: 0 2rem 2rem 0;
        padding-left: 30px;
	}
	::v-deep(.ff-toggle-button.p-selectbutton .p-button:not(.p-highlight):hover) {
		background: #32364E;
        color: #FFFFFF; 
	}
	::v-deep(.ff-toggle-button.disabled.p-selectbutton .p-button:not(.p-highlight):hover) {
		background: #FFFFFF;
        color: #32364E; 
		border-color: #32364E;
		cursor: default;
	}
	::v-deep(.ff-toggle-button.p-selectbutton .p-button-label) {
		padding: 0px;
	}

.p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    height: 100%;
}

::v-deep(.ff-list-item-container) {
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.ff-list-item-container:last-child) {
    border-bottom: none;
}

@media (max-width: 760px) {
    .ff-search-card.card {
        padding: 16px 0px 0px;
        margin-bottom: 0px;
    }

    .ff-card-header {
        font-size: 1.25rem;
    }

    .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
        height: calc(100% - 52px);
    }

}
</style>