<template>
    <div class="card">
       
       
            <h2>Payment Settings</h2>
             <LoadingSpinner :loading='loading' v-if='loading'/>
            <div v-if="!loading">
                <span class="settings-description">
                    <p>You can monetize groups by charging a fee for membership and paywalling access to courses and other investment education related resources.</p>
                    
                    <p></p>
                    <p>To enable monetization you'll need to connect a Stripe account to the INVRS platform.</p>
                    <p></p>
                </span>
                <span v-if="sellerAccountStatus == 'noAccount'" class="settings-description">
                     <p><b>Payment Account Status:</b> No Account</p>
                    <p>You haven't connected a Stripe Account yet.</p>  
                   
                    <Button class="green-button__secondary " label="Connect with Stripe" @click="connect()" icon="pi pi-external-link" :loading="connecting"/>
                </span>
                <span v-else-if="sellerAccountStatus == 'chargesDisabled' || sellerAccountStatus == 'payoutsDisabled'" class="settings-description">
                    <p><b>Payment Account Status:</b> Incomplete</p>
                    <p>You've started to connect a Stripe Account but you're still missing some info. </p>
                    <p>Note that after completing the initial onboarding sequence further confirmation of your personal details may be required.  
                        Click the "Continue Stripe Setup" button to confirm your personal details or check your stripe account status.
                        </p>
                    <p>Stripe may take a few minutes to update the status of your account.  
                        If your account status does not change and you have submitted all required information, you can create a support ticket and we'll look into it.</p>
                    
                    <Button class="green-button__secondary " label="Continue Stripe Setup" @click="connect()" icon="pi pi-external-link" :loading="connecting"/>
                </span>
                <span v-else class="settings-description">
                    <p><b>Payment Account Status:</b> Enabled</p>
                    <p>You're Stripe Account is enabled.</p>
                    <Button class="green-button__secondary " label="Manage" @click="connect()" icon="pi pi-external-link" :loading="connecting"/>
                    
                </span>
                <span class="settings-description">
                <div style="padding: 0px 15px 0px 0px; font-size:12px;">
                    <p><b>Account Eligibility:</b> </p>
                    <p>Monetization is primarily intended to support <b>investment education providers and content creators</b>.
                    </p>
                    <p>
                    All paywalled groups and resources are <b>periodically reviewed and monitored</b> to ensure there are no violations of our payment provider's rules and restrictions
                        such as <b>Pyramid Schemes, Multi-Level Marketing, Pump and Dump and other unfair, deceptive or abusive acts or practices</b>.
                    </p>
                    <p>
                        You can see the full list of restricted and prohibited goods and services<a href="https://stripe.com/en-ca/legal/restricted-businesses#prohibited-businesses" target="_blank"> here</a>.
                    </p>
                    </div>
                </span>
              
            </div>
        
        
    </div>
</template>

<script>

import PaymentService from '../../service/PaymentService';
import LoadingSpinner from '../common/LoadingSpinner.vue';


export default {
    name: 'SellerCard',
   
    components: { LoadingSpinner },

    data() {
        return {
          
           sellerAccountStatus: 'noAccount',
           loading: false,
           connecting: false,
        };
    },

    mounted() {
        console.log("mounting payment settings")
        this.loading = true;
        PaymentService.getSellerAccountStatus().then((resp) => {
            this.loading = false;
            if (resp.data.status == 'success') {
               
                this.sellerAccountStatus = resp.data.sellerAccountStatus;
                if( this.sellerAccountStatus == 'chargesDisabled' || this.sellerAccountStatus == 'payoutsDisabled'){
                    this.startStatusPolling();
                }
            } else {
                console.log("error getting seller account status and link", resp.data.message);
            }
        })
        .catch(err => {
            this.loading = false;
             console.log("Unexpected error getting seller account status and link", err);
        });

       
    },

    methods: {
        startStatusPolling() {
            setTimeout(() => {this.doPoll()}, 10000);
        },

        doPoll() {
             this.connecting = true;
             console.log("polling for stripe account status update...")
            PaymentService.getSellerAccountStatus().then((resp) => {
                this.connecting = false;
                if (resp.data.status == 'success') {
                
                    this.sellerAccountStatus = resp.data.sellerAccountStatus;
                    if( this.sellerAccountStatus == 'chargesDisabled' || this.sellerAccountStatus == 'payoutsDisabled'){
                        this.startStatusPolling();
                    }
                    } else {
                        console.log("error getting seller account status and link", resp.data.message);
                    }
            })
            .catch(err => {
                this.connecting = false;
                console.log("Unexpected error getting seller account status and link", err);
            });
        },


       connect(){
         this.connecting = true;
         PaymentService.getSellerOnboardingLink().then((resp) => {
            
            if (resp.data.status == 'success') {
                
                this.sellerAccountStatus = resp.data.sellerAccountStatus;
                if( this.sellerAccountStatus == 'accountEnabled') {
                    window.open(resp.data.accountLink,'_blank');
                    this.connecting = false;
                }
                else {
                    window.location.href = resp.data.accountLink;
                }


            } else {
                this.connecting = false;
                console.log("error getting seller account status and link", resp.data.message);
                this.$toast.add({ severity: 'error', summary: 'Error connecting to Stripe. Contact us if the issue persists.', detail: '', life: 5000,  group: 'center'  });
            }
        })
        .catch(err => {
             this.connecting = false;
             console.log("Unexpected error getting seller account  link", err);
             this.$toast.add({ severity: 'error', summary: 'Error connecting to Stripe. Contact us if the issue persists.', detail: '', life: 5000,  group: 'center'  });
        });

           
       }
    },
};
</script>

<style scoped>
.card {
    position: relative;
    display: flex;
    flex-direction: column;
}

.settings-section {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}
.settings-section:last-child {
    margin-bottom: 0;
}

.settings-title {
    margin: 24px 0 0 0;
}
.settings-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* color: #bfbfbf; */
}
.settings-label {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
}
.view-blocked-users-button {
    width: fit-content;
    background: none !important;
    border: none;
    padding: 0 !important;
    font-weight: 400;
    font-size: 14px;
    color: #32364e;
    text-decoration: underline;
}
.view-blocked-users-button:hover {
    color: black;
}
</style>