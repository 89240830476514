<template>
   <Dialog header="Edit Profile" v-model:visible="display" :modal="true" :closable="true" class='edit-profile-modal' @hide="onHide">
       <ScrollPanel class='scroll-panel'>
       <!-- <div class='scroll-panel'>  -->
            <div class='avatar-container'>
                <div class='rounded-image'>
                    <img id='defaultUserImage' src='../../../public/Default-Avatar.jpeg' width='275' v-show="!imageUrl && data.type === USER_TYPES['USER']"/>
                    <img id='defaultGroupImage' src='../../../public/Default-Group-Avatar.png' width='275' v-show="!imageUrl && data.type === USER_TYPES['GROUP']"/>
                    <img :id='imageElementId' :src='imageUrl' v-show="imageUrl" />
                </div>
                <div class='profile-picture-button-container'>
                    <Button label='Delete' class='delete action-button' v-show='imageUrl' @click='removeImportedImage'/>
                    <span class='p-button p-component action-button'  @mouseup='openFileDialogForImportingImage'>
                        <span class="p-button-label">{{uploadButtonLabel}}</span>
                        <ImageFileInput :srcImageId='imageElementId' @selectedImage='selectedImage($event)' @openErrorModal='openErrorModal($event)'/>
                    </span>
                    <Button label='Generate' class='generate action-button' @click='generateImage'/>
                    <span ><i class="pi pi-question-circle" @click="showAttribution"></i>
                    <OverlayPanel ref="attributionPanel" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '323px'}">
                       <div   class="p-component attribution-card" v-html="attribution">
                        </div>
                    </OverlayPanel>
                    </span>
                </div>
            </div>

            <div class='input-section'>
                <span :class="{'p-float-label': true, 'input-error': v$.name.$error }">
                    <InputText id="name" type="text" v-model="name" @input="resetError('name')"/>
                    <label for="name" class='input-label'>Name</label>
                </span>
                <template v-for="error of v$.name.$errors" :key="error.$uid">
                    <span class='error-message'>{{error.$message}}</span>
                </template>
            </div>

            <div class='input-section' v-if="isEditingUserProfile">
                <div :class="{'p-float-label': true, 'input-error': v$.username.$error }">
                    <InputText id="username" type="text" v-model="username"  @input="resetError('username')"/>
                    <label for="username" class='input-label'>Username</label>
                </div>
                <template v-for="error of v$.username.$errors" :key="error.$uid">
                    <span class='error-message'>{{error.$message}}</span>
                </template>
            </div>
          
            <div class='input-section'>
                <div :class="{'p-float-label': true, 'input-error': v$.link.$error }">
                    <InputText id="link" type="text" v-model="link"  @input="resetError('link')"/>
                    <label for="link" class='input-label'>Link</label>
                </div>
                <template v-for="error of v$.link.$errors" :key="error.$uid">
                    <span class='error-message'>{{error.$message}}</span>
                </template>
            </div>

            <div class='input-section'>
                <span :class="{'p-float-label': true, 'input-error': v$.bio.$error}">
                    <Textarea id="bio" v-model="v$.bio.$model" rows="5" cols="30" class='bio-textarea' ref='bioTextArea'/>
                    <label for="bio" class='input-label'>Add a bio</label>
                    <span class='bio-counter' :style="{color: v$.bio.$error ? '#E63E3E': null}">{{ (bio ? bio.length:0)}} / {{bioCharLimit}} </span>
                </span>
            </div>

            <div class='update-interests-container' v-if="data.type === USER_TYPES['USER']">
                <Button label='Update Interests' class='update-interests-button' @click='displayTagDialog = true' />
            </div>
       <!-- </div> -->
       </ScrollPanel>

       <Button label='Save' class='action-button accept' @click='submit' :loading="submitting" :disabled="disableSaveButton"/>
   </Dialog>

   <ImportImageErrorModal ref='importImageErrorModal'/>

   <Dialog class='tag-dialog' :modal='true' :draggable='false' v-model:visible="displayTagDialog" :closable="false">
        <template #header>
            <div class='header'>
                <span class='dialog-title'>Update Interests</span>
            </div>
        </template>
        <div class="tags-container">
            <span class="tags-title">Investment Styles</span>
            <div class="investment-tags">
                <div :class="{hidden: chunk.hidden, row: true }" v-for="(chunk, i) in displayTags['Investment Styles']" :key="i">
                    <template v-for="tag in chunk.chunk" :key="tag.info.tagId">
                        <Button :label="tag.info.name" :class="{ 'tag-button': true, tag: true, selected: tag.selected }" @click="tag.selected = !tag.selected" />
                    </template>
                </div>
                <Button :label="seeMoreLabel['Investment Styles']" class="see-more-button p-button-text" @click="toggleShowHiddenRows('Investment Styles')" />
            </div>

            <span class="tags-title">Investment Categories</span>
            <div class="investment-tags">
                <div :class="{hidden: chunk.hidden, row: true }" v-for="(chunk, i) in displayTags['Investment Categories']" :key="i">
                    <template v-for="tag in chunk.chunk" :key="tag.info.tagId">
                        <Button :label="tag.info.name" :class="{ 'tag-button': true, tag: true, selected: tag.selected }" @click="tag.selected = !tag.selected" />
                    </template>
                </div>
                <Button :label="seeMoreLabel['Investment Categories']" class="see-more-button p-button-text" @click="toggleShowHiddenRows('Investment Categories')" />
            </div>
            <span class="tags-title">Sectors</span>
            <div class="investment-tags">
                <div :class="{hidden: chunk.hidden, row: true }" v-for="(chunk, i) in displayTags['Sectors']" :key="i">
                    <template v-for="tag in chunk.chunk" :key="tag.info.tagId">
                        <Button :label="tag.info.name" :class="{ 'tag-button': true, tag: true, selected: tag.selected }" @click="tag.selected = !tag.selected" />
                    </template>
                </div>
                <Button :label="seeMoreLabel['Sectors']" class="see-more-button p-button-text" @click="toggleShowHiddenRows('Sectors')" />
            </div>
            <span class="tags-title">Industries</span>
            <div class="investment-tags">
                <div :class="{hidden: chunk.hidden, row: true }" v-for="(chunk, i) in displayTags['Industries']" :key="i">
                    <template v-for="tag in chunk.chunk" :key="tag.info.tagId">
                        <Button :label="tag.info.name" :class="{ 'tag-button': true, tag: true, selected: tag.selected }" @click="tag.selected = !tag.selected" />
                    </template>
                </div>
                <Button :label="seeMoreLabel['Industries']" class="see-more-button p-button-text" @click="toggleShowHiddenRows('Industries')" />
            </div>
            <span class="tags-title" style="margin-top:32px;">Contests</span>
            <div class="investment-tags">
                <div :class="{hidden: chunk.hidden, row: true }" v-for="(chunk, i) in displayTags['Contests']" :key="i">
                    <template v-for="tag in chunk.chunk" :key="tag.info.tagId">
                        <Button :label="tag.info.name" :class="{ 'tag-button': true, tag: true, selected: tag.selected }" @click="tag.selected = !tag.selected" />
                    </template>
                </div>
                <Button :label="seeMoreLabel['Contest']" class="see-more-button p-button-text" @click="toggleShowHiddenRows('Contests')" />
            </div>
        </div>
        <template #footer>
            <Button label='Done' class='action-button' @click='closeUpdateInterestsDialog'/>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import ScrollPanel from 'primevue/scrollpanel';
import Textarea from 'primevue/textarea';

import ImageFileInput from '../fileInput/ImageFileInput';
import ImportImageErrorModal from '../errors/ImportImageErrorModal';

import CustomValidationUtils from '../../utilities/CustomValidationUtils';
import DsUtils from '../../utilities/DataStructureUtils';
import GroupService from '../../service/GroupService';
import UserService from '../../service/UserService';
import FeedService from '../../service/FeedService';
import { USER_TYPES } from '../../common/constants';

import { mapState } from 'vuex';
import EventBus from '../../event-bus';
import useVuelidate from '@vuelidate/core'
import { required, helpers, maxLength, minLength } from '@vuelidate/validators';
import OverlayPanel from 'primevue/overlaypanel';


import { createAvatar } from '@dicebear/core';
// import { lorelei } from '@dicebear/collection';
//import { shapes } from '@dicebear/collection';
import { rings,croodlesNeutral,adventurerNeutral,bigEars,bigSmile,miniavs,personas,notionists,avataaars   } from '@dicebear/collection';

const investmentStyles = 'Investment Styles';
const investmentCategories = 'Investment Categories';
const sectors = 'Sectors';
const industries = 'Industries';
const contests = 'Contests';
const avatarLibs = [croodlesNeutral,adventurerNeutral,bigEars,bigSmile,miniavs,personas,notionists,avataaars];

export default {
    name: 'EditProfileModal',
    setup: () => ({ v$: useVuelidate() }),
    props: {
        data: {
            type: Object, 
            required: true
        },
        linkObject: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            //CONSTANTS
            USER_TYPES,

            bioCharLimit: 499,
            educationBioLimit: 499,
            display: false,
            name: null,
            username: null,
            bio: null,
            link: null,
            
            imageElementId: 'userProfilePicture',
            imageFile: null,
            imageUrl: null,
            submitting: false,

            numChunks: 5,
            displayTagDialog: false,
            displayTags: {
                [investmentStyles]: [],
                [investmentCategories]: [],
                [sectors]:[],
                [industries]: [],
                [contests]: []
            },
            seeMoreLabel: {
                [investmentStyles]: 'See More',
                [investmentCategories]: 'See More',
                [sectors]: 'See More',
                [industries]: 'See More',
                [contests]: 'See More',
            },
            attribution:`Avatars generated by <a target="_" href="https://avatars.dicebear.com">Dicebear</a> and <a target="_" href="https://robohash.org">Robohash</a>`,
        }
    },
    computed: {
        ...mapState(['selectedGroup']),
        uploadButtonLabel() {
            if (this.imageUrl) {
                return 'Change';
            } else {
                return 'Upload';
            }
        },

        disableSaveButton() {
            if (this.isEditingUserProfile) {
                return false;
            } else {
                return !this.bio;
            }
        },

        isEditingUserProfile() {
            return this.data.type === USER_TYPES['USER']
        },

        validationsObject() {
            if (this.isEditingUserProfile) {
                return {
                    username: {
                        required,
                        maxLength: maxLength(30),
                        minLength: minLength(6),
                        noSpecialCharacters: helpers.withMessage('Should not contain any special characters.', CustomValidationUtils.noSpecialCharacters),
                        noSpaces: helpers.withMessage('Should not contain any spaces, only underscores.', CustomValidationUtils.noSpaces),
                    },
                    name: {
                        required
                    },
                    bio: {
                        maxLength: maxLength(this.bioCharLimit)
                    },
                    link: {
                        maxLength: maxLength(300),
                        properLinkFormat: helpers.withMessage(`Link should not contain spaces and should begin with 'https://' or 'http://'`, CustomValidationUtils.properLinkFormat),
                    },
                }
            } else {
                if( this.selectedGroup?.educational) {
                    return {
                        name: {
                            required,
                            maxLength: maxLength(100),
                            minLength: minLength(6),
                            //onlySpacesHyphensUnderscores: helpers.withMessage('Should not contain any special characters. (Spaces, hyphens, and underscores are allowed)', CustomValidationUtils.onlySpacesHyphensUnderscoresParentheses),
                            //noEndSpacesAndOneSpaceInBetween: helpers.withMessage('No spaces (spaces, hypens, and underscores) at either end, and only one space between each letter.', CustomValidationUtils.noEndSpacesAndOneSpaceInBetween)
                        },
                        bio: {
                            maxLength: maxLength(this.educationBioLimit)
                        },
                        link: {
                            maxLength: maxLength(300),
                            properLinkFormat: helpers.withMessage(`Link should not contain spaces and should begin with 'https://' or 'http://'`, CustomValidationUtils.properLinkFormat),
                        },
                    }
                }
                else {
                    return {
                        name: {
                            required,
                            maxLength: maxLength(100),
                            minLength: minLength(6),
                            //onlySpacesHyphensUnderscores: helpers.withMessage('Should not contain any special characters. (Spaces, hyphens, and underscores are allowed)', CustomValidationUtils.onlySpacesHyphensUnderscoresParentheses),
                            //noEndSpacesAndOneSpaceInBetween: helpers.withMessage('No spaces (spaces, hypens, and underscores) at either end, and only one space between each letter.', CustomValidationUtils.noEndSpacesAndOneSpaceInBetween)
                        },
                        bio: {
                            maxLength: maxLength(this.bioCharLimit)
                        },
                        link: {
                            maxLength: maxLength(300),
                            properLinkFormat: helpers.withMessage(`Link should not contain spaces and should begin with 'https://' or 'http://'`, CustomValidationUtils.properLinkFormat),
                        },
                    }
                }
                
            }
        }
    },

    components: {
        Dialog, Textarea, ImageFileInput, ImportImageErrorModal, ScrollPanel, OverlayPanel
    },
    
    created() {
        this.fetchCuratedTags();
    },

    methods: {
        showAttribution(event) {
            this.$refs.attributionPanel.toggle(event);
        },

         getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
        },

        async generateImage() {

           
            if (this.data.type === USER_TYPES['GROUP']) {
                const avatar = createAvatar(rings, {
                    seed: this.selectedGroup.groupId+this.selectedGroup.name+Math.random(),
                  
                    });
                const png = await avatar.png();
                this.imageUrl = await png.toDataUri();
                this.imageFile = null;
                //resp = await GroupService.generateGroupAvatar(this.selectedGroup.groupId);

            }
            else {
                //resp = await UserService.generateAvatar();
                const lib = avatarLibs[this.getRandomInt(0,avatarLibs.length)]
                const avatar = createAvatar(lib, {
                    seed: this.data.username+Math.random(),
                  
                    });
                const png = await avatar.png();
                this.imageUrl = await png.toDataUri();
                this.imageFile = null;
            //}
            }

            
        }, 
        /* UPDATE INTERESTS/FOLLOWED TAGS MODAL METHODS */
        preselectTags() {
            for(const key in this.displayTags) {
                this.displayTags[key].forEach((chunk) => {
                    chunk.chunk.forEach((tag) => {
                        tag.selected = Object.keys(this.$store.state.users.user.analyst.followed.followedTags).map(tag => Number(tag)).includes(tag.info.tagId);
                    })
                })
            }
        },

        fetchCuratedTags() {
            FeedService.getCuratedTags().then((response) => {

                for (let key in this.displayTags) {
                    let chunks = DsUtils.sliceArrayInNumChunks(response.data[key], this.numChunks);

                    chunks = chunks.map((chunk) => {
                        const newChunk = chunk.reduce(this.addSelectedParameterToTag, []);

                        return newChunk;
                    });

                    this.displayTags[key] = chunks.reduce(this.hideFirstTwoChunks, []);

                    this.preselectTags();
                }
            });
        },

        hideFirstTwoChunks(total, chunk, i) {
            total.push({
                chunk: chunk,
                hidden: i > 1 ? true : false,
            });

            return total;
        },
        addSelectedParameterToTag(total, tag) {
            total.push({
                info: tag,
                selected: false,
            });

            return total;
        },

        toggleShowHiddenRows(tagType) {
            let arr = this.displayTags[tagType];

            const res = arr.map((el, i) => {
                if (i > 1) {
                    const chunk = el.chunk;

                    if (el.hidden) {
                        return {
                            chunk: chunk,
                            hidden: false,
                        };
                    }

                    return {
                        chunk: chunk,
                        hidden: true,
                    };
                }

                return el;
            });

            this.displayTags[tagType] = res;

            if (this.seeMoreLabel[tagType] == 'See More') {
                this.seeMoreLabel[tagType] = 'See Less';
            } else {
                this.seeMoreLabel[tagType] = 'See More';
            }
        },

        closeUpdateInterestsDialog() {
            this.displayTagDialog = false;
        },

        /* EDIT PROFILE MODAL RELATED METHODS */

        onHide() {
            this.preselectTags();
        },

        open() {            
            this.display = true;

            this.name = this.data.fullName,
            this.username = this.data.type === USER_TYPES['USER'] ? this.data.username : null,
            this.bio = this.data.bio;
            this.link = this.data.link;

            if( this.data.avatar ){
                // this.imageFile = {
                //     name: this.user.avatar.substring(this.user.avatar.lastIndexOf('/')+1),
                //     url: this.user.avatar
                // };
                this.imageUrl = this.data.avatar;
            }

            // Triggers error check to see if bio is already longer than the char limit
            if (this.bio && this.bio.length > this.bioCharLimit) {
                this.v$.bio.$touch();
            }
        },

        getLinkList(linkObject, analystId) {
            //console.log("getLinkList",linkObject, this.selectedGroup, analystId );
            const linkList = [];
            
            if (!linkObject && this.link == null) {
                return linkList;
            } 

            if(linkObject) {
                linkList.push({
                    analystLinkId: linkObject.analystLinkId,
                    analystId: analystId ? analystId : this.$store.state.users.user.analyst.analystId,
                    url: this.link,
                    createdDate: linkObject.createdDate,
                    revisionDate: linkObject.revisionDate,
                });
            } else {
                linkList.push({
                    analystId: analystId ? analystId : this.$store.state.users.user.analyst.analystId,
                    url: this.link,
                });
            }

            return linkList;
        },

        // Method to submit user profile info to backend
        submit() {
            
            this.v$.name.$touch();

            if (this.data.type === USER_TYPES['USER']) {
                this.v$.username.$touch();
                this.v$.link.$touch();

                if (this.v$.$errors.length == 0) {
                    //this.display = false;
                    this.submitting = true;
                    this.v$.$reset();

                    let addedTags = [];
                    let deselectedTags = [];

                    for(const key in this.displayTags) {
                        this.displayTags[key].forEach((chunk) => {
                            chunk.chunk.forEach((tag) => {
                                const actualTag = tag.info;

                                // Need to check the tags that the user is currently following are not selected on the dialog
                                if (Object.keys(this.$store.state.users.user.analyst.followed.followedTags).map(tag => Number(tag)).includes(actualTag.tagId)) {
                                    if (!tag.selected) {
                                        deselectedTags.push(actualTag);
                                    }
                                } else { // Need to check other tags that the user is not following
                                    if (tag.selected) {
                                        addedTags.push({...actualTag, 'analystId': this.$store.state.users.user.analyst.analystId});
                                    }
                                }
                            })
                        })
                    }

                    //data package of user profile info below
                    const data = {
                        username: this.username,
                        fullName: {
                            first: null,
                            middle: null,
                            last: null
                        },
                        avatar: null,
                        bio: this.bio,
                        linkList: this.getLinkList(this.linkObject),
                        sid: this.$store.state.sid,
                        tagsToAdd: addedTags,
                        tagsToRemove: deselectedTags
                    }

                    if( this.imageFile ){
                        data.avatar = this.imageFile;
                    }
                    else if( this.imageUrl ){
                        data.avatar = this.createAvatarFromUrl(this.imageUrl);
                    }
                    this.parseFullName(data)

                    UserService.updateAnalystProfile(this.$store.state.users.user.analyst.analystId, data).then((resp)=>{
                        this.submitting = false;
                        if( resp.data.status == "success") {
                            this.display = false;

                            this.$store.commit("users/SET_ANALYST", resp.data.analyst);
                        
                            this.$toast.add({ severity: 'success', summary: 'Profile updated.', life: 1500, group: 'center' });
                            this.EventBus.emit('profile-updated');
                        }
                        else if( resp.data.message == "Invalid Name") {
                            this.$toast.add({ severity: 'error', summary: 'Sorry, the name specified is invalid or blocked.',  life: 1500, group: 'center' });
                        }
                        else if( resp.data.message == "Invalid Username") {
                            this.$toast.add({ severity: 'error', summary: 'Sorry, the username is invalid or taken.',  life: 1500, group: 'center' });
                        }
                        else {
                            
                            console.log(JSON.stringify(resp));
                            this.$toast.add({ severity: 'error', summary: 'Sorry, there was an error updating profile. Please try again later or contact support.',  life: 1500, group: 'center' });
                        }
                    });
                    
                }
            } else if (this.data.type === USER_TYPES['GROUP']) {
                 if (this.v$.$errors.length == 0) {
                    //this.display = false;
                    let avatar = null;
                    this.submitting = true;
                    this.v$.$reset();

                    if( this.imageFile ){
                        avatar = this.imageFile;
                    }
                    else if( this.imageUrl ){
                        avatar = this.createAvatarFromUrl(this.imageUrl);
                    }

                    GroupService.updateGroup( this.selectedGroup.groupId, this.name, this.name.replaceAll(" ", "_"), 
                    this.getLinkList(this.selectedGroup.links ? this.selectedGroup.links[0] : null, this.selectedGroup.groupAnalyst?.analystId),
                    this.selectedGroup.groupTypeId, this.selectedGroup.isPrivate, this.selectedGroup.isVisible, 
                    this.bio, avatar, this.selectedGroup.allowMemberPosts, this.selectedGroup.allowMemberComments, this.selectedGroup.allowMemberLikes).then(resp => {
                        this.submitting = false;

                        if( resp.data.status == 'success') {
                            let group = resp.data.group;
                            //console.log("updated group", group);
                            this.display = false;
                            group.isMember = true;
                            this.$toast.add({ severity: 'success', summary: 'Profile updated.', life: 1500, group: 'center' });

                            this.$store.commit("SET_SELECTED_GROUP", group);
                            this.$store.commit('MARK_CHANNELS_STALE');

                            this.link = this.selectedGroup?.links[0];
                        } else if( resp.data.status === 'Name Taken') {
                            console.log("group name taken")
                            this.$toast.add({ severity: 'error', summary: `Group name taken, please choose another name for your group.`, life: 2500, });
                            
                        
                        } else {
                            console.error('error creating group ', + resp.data.message);
                            this.$toast.add({ severity: 'error', summary: `There was an error creating your group.  Please try again later.  If the problem presist please contact support@invrs.com`, life: 2500, });
                        }
                    });
                    
                }
            }
        },

        createAvatarFromUrl(url){
            let name = url.substring(url.lastIndexOf('/')+1);
            if( name.indexOf('?') > 0 ) { //trim off any ? from name
                name = name.substring(0, name.indexOf('?'));
            }
             if( name ) {
                if( name.length > 15){
                    name = name.substring(0, 14);
                }
                name = name.replaceAll('+', '');
                name = name.replaceAll('/', '');
                name = name.replaceAll('=', '');
            }
            return { name: name, url: url};
                
        },

         parseFullName(data) {
            // Splitting up inputted name by spaces
            const fullName = this.name.trim().split(' ');

            if (fullName.length == 1) {
                // Only first name
                data.fullName.first = fullName[0];
            } else if (fullName.length == 2) {
                // First and last name
                data.fullName.first = fullName[0];
                data.fullName.last = fullName[1];
            } else if (fullName.length == 3) {
                // First, middle, and last names
                let i = 0;

                for (let field in data.fullName) {
                    data.fullName[field] = fullName[i++];
                }
            }
        },

        openFileDialogForImportingImage() {
            EventBus.emit('open-file-dialog');
        },
        selectedImage(importedImage) {
            this.imageFile = importedImage;
            this.imageUrl = importedImage.url;
        },
        openErrorModal(errorMessage) {
            this.$refs.importImageErrorModal.open(errorMessage)
        },
        removeImportedImage() {
            document.getElementById(this.imageElementId).src = ""; // Prevents former image from rendering when uploading new image
            this.imageFile = null;
            this.imageUrl = null;
        },

        resetError(field) {
            this.v$[field].$reset();
        },
    },

    validations() {
        return this.validationsObject;
    }
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.edit-profile-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 390px;
    height: 76vh;
	box-shadow: none;
}

.edit-profile-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

.edit-profile-modal .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
}

.edit-profile-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.edit-profile-modal .p-dialog-content {
    /* display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; */
    height: 100%;
    width: 100%;
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 25px 25px;
    /* align-items: center;
    justify-content: space-evenly; */
    border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
    overflow-y: hidden;
}

.edit-profile-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.tag-dialog {
    font-family: 'Trebuchet MS', 'Verdana';
    max-height: calc(100vh - 100px);
    max-width: 700px;
    border-radius: 16px;
}
.tag-dialog .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
    padding: 16px 16px;
}

.tag-dialog .p-dialog-content {
    border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
    padding: 16px 0px;
}
.tag-dialog .p-dialog-content::-webkit-scrollbar {
	/* width */
	width: 10px;
    
}
.tag-dialog .p-dialog-content::-webkit-scrollbar-thumb {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}

.tag-dialog .p-dialog-footer {
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
    padding: 16px;
    text-align: center;
}

</style>
<style scoped>
*:not(.pi),  ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}


.attribution-card {

    align-items: center;
    width: 100%;
    padding: 5px;
   
}

.edit-profile-modal .input-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.edit-profile-modal .input-section {
    padding-bottom: 30px;
}
.edit-profile-modal .p-inputtextarea.bio-textarea {
    color: black;
    padding-right: 25px;
    resize: none;
}
.edit-profile-modal .p-inputtext {
    color: #32364e;
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 25px;
}
.edit-profile-modal .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
/* .edit-profile-modal .p-float-label textarea:focus ~ label, */
.edit-profile-modal .p-float-label input:focus ~ label {
    font-size: 12px;
}

.edit-profile-modal .links-input ~ label,
.edit-profile-modal .links-input {
    font-size: 18px;
}
.edit-profile-modal .bio-textarea::-webkit-scrollbar {
	/* width */
	width: 8px;
}
/* .edit-profile-modal .bio-textarea::-webkit-scrollbar-track {
	background-color: #F2F2F2;
	opacity: 1;
	transition: background-color .2s;
    top: calc(100% - 2px);
} */
.edit-profile-modal .bio-textarea::-webkit-scrollbar-thumb {
	background-color: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 9px solid #BFBFBF;  /* creates padding around scroll thumb */
}
.edit-profile-modal .bio-counter {
    font-size: 12px;
}

.p-float-label {
    width: 100%;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}
.input-error .p-inputtext:hover,
.input-error .p-inputtext:enabled:focus,
.input-error .p-inputtext {
    border-color: #E63E3E;
}
.input-error .input-label {
    color: #E63E3E;
}
.error-message:nth-child(2) {
    padding-top: 10px;
}
.error-message {
    color: #E63E3E;
    padding-left: 10px;
}

.action-button {
    font-size: 12px;
    background: #FFFFFF;
    color: #32364e;
    border: 2px solid #33CC99;
    padding: 10px 18px;
    border-radius: 30px;
 }
 .action-button:hover {
	color: #FFFFFF;
	background: #33CC99;
 }
 .action-button:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

 .edit-profile-modal .p-dialog-content .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
 }

  .edit-profile-modal .p-dialog-content .avatar-container .rounded-image {
    display: flex;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    border: 3px solid #32364e;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

 .edit-profile-modal .p-dialog-content .avatar-container .profile-picture-button-container {
    display: flex;
    margin-bottom: 30px;
}

.edit-profile-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete:enabled:focus,
.edit-profile-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete {
    border-color: #E63E3E;
    margin-right: 10px;
}
 .edit-profile-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete:hover {
    background: #E63E3E;
    border-color: #E63E3E;
}

.edit-profile-modal .p-dialog-content .avatar-container .profile-picture-button-container .generate:enabled:focus,
.edit-profile-modal .p-dialog-content .avatar-container .profile-picture-button-container .generate {
    
    margin-left: 10px;
}

.edit-profile-modal .p-dialog-content .update-interests-container {
    display: flex;
    justify-content: center;
}

 .edit-profile-modal .p-dialog-content .accept {
    margin-top: 15px;
    width: 100%;
 }

 .edit-profile-modal .scroll-panel {
    width: 100%;
    height: calc(100% - 46px);
 }

::v-deep(.p-scrollpanel-bar.p-scrollpanel-bar-y) {
    background-color: #BFBFBF;
	opacity: 1;
	transition: background-color .2s;
}
::v-deep(.p-scrollpanel-content) {
    height: calc(100% - 18px);
    width: calc(100% - 10px);
;    padding: 0 18px 0 0;
    position: relative;
    overflow: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
 }

 /* UPDATE INTERESTS MODAL STYLING */

.tag-dialog .p-dialog-header .header {
    text-align: center;
    width: 100%;
}
.tag-dialog .p-dialog-header .header .dialog-title {
    font-size: 24px;
    font-weight: bold;
    color: #32364e;
}

 .tag-button {
    font-size: 20px;
    background: #ffffff;
    color: #32364e;
    border: 2px solid #33cc99;
    border-radius: 50px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    /* padding: 10px 30px; */
}

.tag-button.selected,
.tag-button:hover {
    color: #ffffff;
    background: #33cc99;
}
.tag-button:enabled:focus {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-color: #33cc99;
}

.card .investment-tags .display-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 74px;
    overflow: hidden;
}
.card .investment-tags .tag-button {
    margin: 0px 8px 8px 0px;
}
.card .investment-tags .see-more-button {
    margin-top: 16px;
}

.tag-dialog .investment-tags {
    justify-content: center;
}
.tag-dialog .investment-tags{
    margin-bottom: 32px;
}

.tags-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 16px;
    color: #32364e;
}
.tags-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tag {
    font-size: 12px;
    justify-content: center;
}
::v-deep(.tag .p-button-label) {
    pointer-events: none;
}

.investment-tags {
    display: flex;
    flex-wrap: wrap;
    /* padding-bottom: 40px; */
}
.investment-tags > .row.hidden {
    display: none;
}
.row {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    justify-content: center;
}
.row:last-of-type {
    margin-bottom: 16px;
}
.row .p-button:not(:last-child) {
    margin-right: 8px;
}

.see-more-button:enabled:hover {
    background: none;
    border: none;
    color: #33cc99;
}
.see-more-button {
    background: none;
    border: none;
    color: #693bf5;
    font-weight: bold;
    padding: 0px;
}
.see-more-button:enabled:focus {
    box-shadow: none;
}

.update-interests-button {
    font-size: 16px;
    background: #FFFFFF;
    color: #32364e;
    border: 1px solid #32364e;
    padding: 8px 16px;
    border-radius: 30px;
 }
 .update-interests-button:hover {
	color: #FFFFFF;
	background: #32364e;
    border-color: #32364e;
 }
 .update-interests-button:enabled:focus {
    box-shadow: none;
    border-color: #32364e;
}

#userProfilePicture {
    width: 10rem;
    height: auto;
}
</style>