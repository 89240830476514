<template>
    <div class="carousel-menu-bar" ref="carouselMenuBar">
        <Carousel
            :key="carouselKey"
            :value="menuItems.filter(keepMenuItemVisible)"
            :numVisible="2"
            :numScroll="numScroll"
            class="carousel"
            ref="carousel"
            :circular="false"
            orientation="horizontal"
            :showIndicators="false"
            :showNavigators="true"
        >
            <template #item="item">
                <div>
                    <Button v-if="item.data.to" 
                        :class="{ active: $route.path === item.data.to, 'p-button-raised': !isMobile, 'p-button-rounded': !isMobile, 'button-mobile': isMobile, [item.data.className]: item.data.className }">
                        <router-link :to="item.data.to" class="pill-menuitem-text">
                            {{ item.data.label }}
                        </router-link>
                    </Button>
                    <Button v-if="item.data.action" @click="callAction(item.data)"
                        :class="{ active: item.data.selected, 'p-button-raised': !isMobile, 'p-button-rounded': !isMobile, 'button-mobile': isMobile }">
                        <span v-if="item.data.action" class="pill-menuitem-text">{{ item.data.label }}</span>
                    </Button>
                </div>
            </template>
        </Carousel>
    </div>
</template>

<script>
import Carousel from 'primevue/carousel';

import { keepMenuItemVisible } from './';

export default {
    name: 'CarouselMenuBar',
    components: {
        Carousel,
    },
    props: {
        menuItems: {
            type: Array,
            required: true,
        },
        isMobile: {
            type: Boolean,
            default: true,
        },
        numScroll: {
            type: Number,
            default: 2,
        },
    },
    data() {
        return {
            activeIndex: 0,
            items: [],
            // cmbResizeObserver: null,
            carouselKey: 0,
        };
    },

    // mounted() {
    //     this.$nextTick(() => {
    //         if (!this.cmbResizeObserver) {
    //             this.cmbResizeObserver = new ResizeObserver(this.onResize).observe(this.$refs.carouselMenuBar);
    //         }
    //     });
    // },
    // unmounted() {
    //     if (this.cmbResizeObserver) {
    //         this.cmbResizeObserver.unobserve(this.$refs.carouselMenuBar);
    //     }
    // },

    methods: {
        keepMenuItemVisible,

        callAction(menuItem) {
            this.menuItems.forEach((item) => {
                if (item === menuItem) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }
            });
            menuItem.action();
        },

        // onResize() {
        //     this.navigationCarouselKey += 1;
        // },
    },
};
</script>

<style scoped>

::v-deep(.p-carousel-items-container) {
    height: 100%;
    align-items: center;
}

::v-deep(.p-button-raised) {
    background: #ffffff;
    color: #32364e;
    border: 0;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

::v-deep(.p-button.active) {
    background: #32364e;
    color: white;
    pointer-events: none;
}

::v-deep(.p-button:enabled:hover) {
    background: #32364e;
    color: white;
    border: 0;
}
::v-deep(.p-link):focus,
::v-deep(.p-button):focus {
    box-shadow: unset !important;
}
::v-deep(.p-link){
    margin: 0 !important;
}
/* ::v-deep(.p-link.p-disabled){
    display: none;
} */

::v-deep(.button-mobile) {
    background: #ffffff;
    color: #32364e;
    border: 1px solid #32364e;
    border-radius: 0;
    width: 100%;
    text-align: center;
    display: block;
}
::v-deep(.button-mobile:enabled:hover) {
    border: 1px solid #32364e;
}
::v-deep(.p-carousel-item-end .button-mobile){
    border-left: 0;
}

</style>