<template>
    <Dialog ref="blockedUsersModal" header="Blocked Users" v-model:visible="display" :modal="true" :closable="true" class="blocked-users-modal">
        <div class="blocked-users-modal-container">
            <LoadingSpinner :showLoading="initLoading" />
            <infinite-scroll v-if="userDmBlocks.length > 0" @infinite-scroll="loadMoreResults" message="" :noResult="noMoreUserDmBlocks" :style="scrollStyle" class="blocked-users-scrollpanel">
                <LoadingSpinner :showLoading="loading" />
                <template v-for="user in userDmBlocks" :key="user.userId">
                    <div class="user-item">
                        <UserAvatar class="user-avatar" :imageSrc="user.blockedAvatar" size="md" />
                        <div class="user-details-container">
                            <span>{{ getFullName(user) }}</span>
                            <span>@{{ user.blockedUserName }}</span>
                        </div>
                        <Button label="Unblock" class="green-button__secondary" @click="unblockUser(user)" />
                    </div>
                </template>
            </infinite-scroll>
            <div v-else-if="!initLoading" style="display: flex; align-items: center">
                <p>The blocked users list is empty.</p>
            </div>
        </div>
        <template #footer> </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';

import UserAvatar from '../profile/UserAvatar.vue';

import ChatService from '../../service/ChatService';

export default {
    name: 'BlockedUsersModal',
    data() {
        return {
            display: false,
            loading: false,
            initLoading: false,
            noMoreUserDmBlocks: false,
            userDmBlocks: [],
            scrollStyle: 'width: 100%; max-height: 100%; height: 100%;',
        };
    },

    components: {
        Dialog,
        UserAvatar,
        LoadingSpinner,
        InfiniteScroll,
    },

    mounted() {},

    methods: {
        open() {
            this.display = true;

            this.refreshResults();
        },

        refreshResults() {
            this.userDmBlocks = [];
            this.initLoading = true;
            this.loading = false;
            this.noMoreUserDmBlocks = false;

            this.fetchUserDmBlocks(10, 0);
        },

        async loadMoreResults() {
            // console.log('loadMoreResults', !this.loading);
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreUserDmBlocks) {
                    this.fetchUserDmBlocks(10, this.userDmBlocks.length);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchUserDmBlocks(limit, offset) {
            ChatService.getDmBlocks(null, limit, offset, null, null).then((resp) => {
                // console.log('fetchUserDmBlocks: ', resp);

                this.initLoading = false;
                this.loading = false;
                const blocked = resp.data.blocked;

                if (blocked.length > 0) {
                    const results = this.userDmBlocks.concat(blocked);

                    this.userDmBlocks = results;

                    this.noMoreUserDmBlocks = this.userDmBlocks.length >= resp.data.total;
                } else {
                    this.noMoreUserDmBlocks = true;
                }
            });
        },

        getFullName(user) {
            if (user) {
                const firstName = user.blockedFirstName ? user.blockedFirstName : '';
                const middleName = user.blockedMiddleName ? user.blockedMiddleName : '';
                const lastName = user.blockedLastName ? user.blockedLastName : '';

                return `${firstName} ${middleName} ${lastName}`;
            } else {
                return '';
            }
        },

        unblockUser(user) {
            ChatService.unBlockUser(user.blockedAnalystId).then((resp) => {
                console.log('unBlockUser: ', resp);
                this.refreshResults();
                this.$store.commit('MARK_CHANNELS_STALE_DM');
                this.$toast.add({ severity: 'success', summary: "Successfully unblocked user!", life: 2000, group: 'center' });
            });
        },
    },
};
</script>
<style>
.blocked-users-modal .p-dialog-content {
    padding-bottom: 0 !important;
    flex-grow: 1;
}

.blocked-users-modal {
    width: 430px !important;
    height: 400px !important;
    box-shadow: none;
}
</style>
<style scoped>
.blocked-users-modal-container {
    display: flex;
    justify-content: center;
}

::v-deep(.p-scrollpanel-content) {
  height: 100% !important;
}

.user-item {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}
.user-avatar {
    min-width: 48px;
    min-height: 48px;
}
.user-details-container {
    margin-left: 2px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
</style>
