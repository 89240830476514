import UserService from '../service/UserService';
import {helpers} from '@vuelidate/validators';

class CustomValidationUtilsImpl {
    grossAmountTooLarge(param) {
        return helpers.withParams(
           { type: 'grossAmountTooLarge', value: param},
           function (value) {
                if (value === '' || value === null) {
                    return true;
                } else {
                    return param <= 1000000000000;
                }
           }
        )
    }

    properLinkFormat(value) {
        if (value === '' || value === null) {
            return true;
        }

        return ((value.startsWith('http://') || value.startsWith('https://')) && !/\s/.test(value));
    }

    onlySpacesHyphensUnderscores(value) {
        if (value === '') {
            return true;
        }

        return !(/[^a-zA-Z0-9_ -]/.test(value));
    }   

    onlySpacesHyphensUnderscoresParentheses(value) {
        if (value === '') {
            return true;
        }

        return !(/[^a-zA-Z0-9_ \-()]/.test(value));
    }   

    noEndSpacesAndOneSpaceInBetween(value) {
        if (value === '') {
            return true;
        }

        return /^[^\-_ ]+(?:[ \-_][^\-_ ]+)*$/.test(value);
    }


   mediumOrStrong(value) {  
      if (value === '') {
          return true;
      }
  
      return (/[0-9]/.test(value) && /\W|_/.test(value) && value.length >= 8) || (/[0-9]/.test(value) && value.length >= 6);
  }

   noSpecialCharacters(value) {
      if (value === '') {
          return true;
      }
  
      return !(/[^a-zA-Z0-9_ ]/.test(value));
  }

  validTitle(value){
    if (value === '') {
        return false;
    }

    return !(/[^a-zA-Z0-9__ \-()' ]/.test(value));
  }
  
  noSpaces(value) {
      if (value === '') {
          return true;
      }
      
      return !/\s/.test(value);
  }
  
  usernameTaken(value) {
      if (value === '') {
          return true;
      }
  
      return new Promise((resolve) => {
          UserService.checkUserNameAvailable(value).then((res) => {
              resolve(res.data.status === 'avail');
          });
      });
  }
  invalidFullName(value) {
    if (value === '') {
        return true;
    }

    return new Promise((resolve) => {
        UserService.checkFullNameValid(value).then((res) => {
            resolve(res.data.status === 'avail');
        });
    });
}
}
   
const CustomValidationUtils = new CustomValidationUtilsImpl();
export default CustomValidationUtils;