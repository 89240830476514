<template>
    <div class='security-list-item-container'>
        <SecurityLogo :security="security" @click="logoClick()"/>
        <div class='text-container'>
            <span class='security-header-text' @click='headerTextClick()'>
                {{security.symbol}}
            </span>
            <span class='sub-text' @click='subTextClick($event)'>
                {{security.name}} - {{security.exchangeSymbol}}
            </span>
        </div>
        <div class='follow-button-container'>
            <FollowButton :followTarget="securityObject" :confirmUnfollow="false" :followVal="true"/>
        </div>
    </div>
</template>

<script>
import FollowButton from '../button/FollowButton.vue';
import SecurityLogo from '../avatar/SecurityLogo.vue';

import SecurityFormatter from '../../common/SecurityFormatter';
import StringUtils from '../../utilities/StringUtils';
import UserUtils from '../../utilities/UserUtils';

export default {
    name: 'FollowedSecurityListItem',
    components: {
        FollowButton, SecurityLogo
    },
    props: {
        security: {
            type: Object,
            required: true
        }
    },
    computed: {
        // Matches JSON object for going to security page from main search bar

        securityObject() {
            const sec = this.security;
            const id = sec.id;
            const name = sec.name;
            let obj = {
                'type': 'Security',
                'symbol': SecurityFormatter.getSymbolViaCdnExchanges(sec),
                'exchangeSymbol': sec.exchangeSymbol,
                'exchangeId':sec.exchangeId
            };


            if (this.security.type === 'fund') {
                obj['name'] = name;
                obj['fundId'] = id;
            } else {
                obj['company'] = name;
                obj['securityId'] = id;
            }

            return obj;
        }
    },
    data() {
        return {
            stringUtils: StringUtils,
            userUtils: UserUtils
        }
    },

    methods: {
        logoClick() {
            this.goToSecurity();
        },
        headerTextClick() {
            this.goToSecurity();
        },
        subTextClick() {
            this.goToSecurity();
        },

        goToSecurity() {
            const sec = this.security;

            if (sec.type === 'equity') {
                this.$store.commit('SET_SELECTED_EQUITY', this.securityObject);
                this.$router.push({ path: '/equity' });
            } else {
                this.$store.commit('SET_SELECTED_FUND', this.securityObject);
                this.$router.push({ path: '/fund' });
            }

            
        }
    }
}
</script>
<style scoped>
.security-list-item-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
.security-list-item-container:last-child {
    border-bottom: none;
}

::v-deep(.p-avatar) {
    margin-right: 16px;
}
::v-deep(.p-avatar:hover) {
    cursor: pointer;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.security-header-text {
    font-size: 16px;
    color: #32364e;
    font-weight: bold;
    margin-bottom: 4px;
}
.security-header-text:hover {
    cursor: pointer;
}

.sub-text {
    font-size: 12px;
    color: #BFBFBF;
}
.sub-text:hover {
    cursor: pointer;
}

.follow-button-container {
    margin-left: auto;
}

@media (max-width: 760px) {
    ::v-deep(.follow-button) {
        padding: 10px !important;
    }

    ::v-deep(.follow-button .p-button-label) {
        font-size: 11px;   
    }
}
</style>