<template>
    <div class='security-search-card card'>
        <span class='security-header-text'>Securities You Follow</span>
        <SearchBar ref='securitySearchBar' @search-input="searchInput" @loading="onLoading" :placeholderText="placeholderText" />
        <LoadingSpinner :showLoading="initLoading" minHeight="100vh"/>
        <infinite-scroll @infinite-scroll="loadMoreResults" message="" :noResult="noMoreSearchResults" :style='scrollStyle'>
            <FollowedSecurityListItem :security="security" v-for="security in searchResults" :key="security.id" />
            <!-- <LoadingSpinner :showLoading="loading && !isSearching" minHeight='100px'/> -->
        </infinite-scroll>
    </div>
</template>

<script>
import SearchBar from '../query/SearchBar.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue'; 
import FollowedSecurityListItem from '../profile/FollowedSecurityListItem.vue';

import FeedService from '../../service/FeedService';
import UserUtils from '../../utilities/UserUtils'
import StringUtils from '../../utilities/StringUtils';

import Mark from 'mark.js';

export default {
    name: 'FollowedSecuritySearch',
    components: {
        LoadingSpinner, SearchBar, InfiniteScroll, FollowedSecurityListItem
    },
    data() {
        return {
            placeholderText: "Search Equities/Funds",
            loading: false,
            initLoading: false,
            currentText: '',
            searchResults: [],
            noMoreSearchResults: false,
            scrollStyle: "width: 100%; height: 500px;",

            stringUtils: StringUtils,
            userUtils: UserUtils
        }
    },

    mounted() {
        this.initLoadSecurites();
    },

    methods: {
        onLoading() {
            this.searchResults = [];
            this.initLoading = true;
            this.loading = false;
        },
        searchInput(val) {
            this.currentText = val;
            this.fetchSecuritiesFromSearch(val, 10, 0);
        },

        initLoadSecurites() {
            this.currentText = '';
            this.searchResults = [];
            this.initLoading = true;
            this.loading = false;
            this.fetchSecuritiesFromSearch(this.currentText, 10, 0);
        },

        async loadMoreResults() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreSearchResults) {
                    this.fetchSecuritiesFromSearch(this.currentText, 10, this.searchResults.length);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchSecuritiesFromSearch(query, limit, offset) {

            FeedService.getFollowedSecurities(query, limit, offset).then(resp => {
                if( resp.data.status == "success" ){
                    const followed = resp.data.results.followed;
                    this.initLoading = false;
                    this.loading = false;
                
                    if (followed.length > 0) {
                        this.searchResults = this.searchResults.concat(followed)
                        this.noMoreSearchResults = false;
                    } else {
                        this.noMoreSearchResults = true;
                    }

                    this.$nextTick(() => {
                        let instance = new Mark(document.querySelectorAll('.security-list-item-container .text-container'));

                        const regex = new RegExp(`^${query}`, 'i');

                        instance.unmark();
                        instance.markRegExp(regex, {
                            'className': 'search-match-highlight',
                        });
                    });
                }
                else {
                    console.log("error doing following search: "+ resp.data.message);
                }
            });
        },

    }
}
</script>
<style scoped>
.security-search-card {
    padding: 16px 0px 0px;
    margin-bottom: 0px;
}

.security-header-text {
    display: block;
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    padding: 0px 0px 16px 16px;
}

::v-deep(.search-bar-container) {
    flex: 1;
}

.p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    height: 100%;
}

::v-deep(.security-list-item-container:last-child) {
    border-bottom: none;
}

@media (max-width: 760px) {
    .security-search-card.card {
        padding: 16px 0px 0px;
        margin-bottom: 0px;
    }

    .security-header-text {
        font-size: 1.25rem;
    }

    .p-scrollpanel ::v-deep(.p-scrollpanel-content) {
        height: calc(100% - 52px);
    }

}
</style>