<template>
    <Dialog class='update-account-visibility-modal' v-model:visible="display" :header="headerText" :dismissableMask="false" :modal="true" @show="onShown">
        <div v-for="account of shareableAccounts" :key="account.investmentAccountId" class='account-checkbox'>
            <Checkbox :id="account.investmentAccountId" :value="account" v-model="selectedAccounts" />
            <label :for="account.investmentAccountId">{{account.name}}</label>
        </div>

        <template #footer>
            <Button class='green-button__primary' :label="buttonLabel" @click='sendUpdatedAccounts' :disabled="disableButton" />
        </template>
    </Dialog>
</template>

<script>
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';

export default {
    name: 'UpdateAccountVisibilityModal',
    emits: ['get-accounts'],
    components: {
        Checkbox, Dialog
    },

    props: {
        headerText: {
            type: String,
            required: true
        },
        buttonLabel: {
            type: String,
            required: true
        },
        accounts: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            display: false,
            shareableAccounts: [],
            selectedAccounts: [],
        }
    },
    computed: {
        unselectedAccounts() {
            return this.accounts.filter(acc => !this.selectedAccounts.includes(acc));
        },

        disableButton() {
            const originalSelected = this.accounts.filter((acc => acc.sharingLevel === 5));

            if (originalSelected.length !== this.selectedAccounts.length) {
                return false;
            } else {
                return originalSelected.every((acc => this.selectedAccounts.includes(acc)));
            }
        }
    },

    methods: {
        open() {
            this.display = true;
        },

        onShown() {
            // console.log("UpdateAccountVisibilityModal",this.accounts);
            this.shareableAccounts = this.accounts.filter((acc => acc.investmentAccountId != -999 
                && acc.investmentAccountId != -998 
                && acc.investmentAccountId != -997));
            this.selectedAccounts = this.shareableAccounts.filter((shareableAcc => shareableAcc.sharingLevel === 5));
        },

        /* We send the new list of accounts that are supposed to be either public or private. That way, we can
        compare the original list with the new one - If both same accounts have different sharing levels, then we 
        update the original account's level with the new account's one */
        sendUpdatedAccounts() {
            // Changing all the accounts in the selected list to be actually publically shared
            const selected = this.selectedAccounts.map((acc) => {
                let obj = Object.assign({}, acc);

                obj.sharingLevel = 5;

                return obj;
            })


            // Changing all the accounts in the unselected list to be actually private
            const unselected = this.unselectedAccounts.map((acc) => {
                let obj = Object.assign({}, acc);

                obj.sharingLevel = 1;

                return obj;
            })

            console.log([...selected, ...unselected])

            this.$emit('get-accounts', [...selected, ...unselected]);
            this.display = false;
        }
    }
}
</script>

<style>
.update-account-visibility-modal {
    font-family: "Trebuchet MS", "Verdana";
    border-radius: 16px;
    max-height: 350px;
    width: 350px;
}

.update-account-visibility-modal .p-dialog-header {
    color: #32364e;
    font-size: 24px;
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.update-account-visibility-modal .p-dialog-content {
    padding: 16px;
}

.update-account-visibility-modal .p-dialog-footer {
    padding: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

</style>

<style scoped>
.account-checkbox {
    font-family: "Trebuchet MS", "Verdana";
    margin-bottom: 16px;
}
.account-checkbox:last-child {
    margin-bottom: 0px;
}

.account-checkbox label {
    color: black;
    font-size: 16px;
    margin-left: 12px;
}
</style>