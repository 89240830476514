<template>
    <PillMenuBar v-if="menuItems" :navigationItems="menuItems" class='motions-menu' header-type="main">
        <template #custom>
            <Button v-tooltip.left="'Create Motion'"
                class='green-button__secondary create-motion-button' @click="onCreateMotionClick">
                <img class="motions-icon" src="/assets/images/motion_icon2.png" />
            </Button>
        </template>
    </PillMenuBar>

    <GroupMotions userVotesPage v-if="selectedMenuItem === MY_VOTES_MENU_ITEM" />
    <GroupMotions userMotionsPage v-else />
</template>

<script>
import PillMenuBar from '../../components/menu/PillMenuBar.vue';
import GroupMotions from '../../components/groups/GroupMotions.vue';

import BrowserUtils from '../../utilities/BrowserUtils';

import EventBus from '../../event-bus';

const MY_MOTIONS_MENU_ITEM = 'My Motions';
const MY_VOTES_MENU_ITEM = 'My Votes';


export default {
    name: 'Motions',
    components: {
        GroupMotions,
        PillMenuBar
    },

    data() {
        return {
            // CONSTANTS
            MY_MOTIONS_MENU_ITEM,
            MY_VOTES_MENU_ITEM,

            menuItems: [
                {label: MY_MOTIONS_MENU_ITEM, action: this.selectMenuItem, selected: true},
                {label: MY_VOTES_MENU_ITEM, action: this.selectMenuItem, selected: false}
			],

            selectedMenuItem: null
            
        };
    },

    mounted() {
        this.selectMenuItem();
    },

    methods: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        selectMenuItem() {
            this.selectedMenuItem = this.menuItems.filter((item => item.selected))[0].label;
        },

        onCreateMotionClick() {
           EventBus.emit('open-create-motion-modal', {userMotionsPage:true});
        },

    },
};
</script>

<style scoped>
::v-deep(*:not(.pi, .material-icons-outlined, .material-symbols-outlined)),
::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.motions-menu {
    padding: 0px 0px 16px !important;
    margin-right: 8px;
    margin-top:16px;
}

::v-deep(.create-motion-button) {
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-radius: 28px;
}

.motions-icon {
    width: 20px !important;
}
</style>