/* This util will be a place for providing useful mutating or manipulating utils
 of any data structure in mind - Array, Object etc.  */ 

class DsUtilsImpl {

    sliceArrayInNumChunks(arr, num) {
     const res = [];
               
     for(let i = 0; i < arr.length; i += num) {
         const chunk = arr.slice(i, i + num);
   
         res.push(chunk);
     }
   
     return res;
    }
   }
   
const DsUtils = new DsUtilsImpl();
export default DsUtils;