<template>
    <div class="card">
        <h2>Settings</h2>
        <span class="settings-description">Adjust your user preferences and save them to your profile.</span>

        <div class="settings-section">
            <h2 class="settings-title">Direct Messages:</h2>
            <span class="settings-label">Enable Messages:</span>
            <InputSwitch v-model="enableDmMessages" class="group-actions-switch" @change="switchDmMessages" />
            <span class="settings-label">Enable Notifications:</span>
            <InputSwitch v-model="enableDmNotifications" class="group-actions-switch" @change="switchDmNotifications" />
            <span class="settings-label">Blocked users:</span>
            <Button class="view-blocked-users-button" label="View Blocked Users" @click="viewBlockedUsers" />
        </div>
        <div class="settings-section">
            <h2 class="settings-title">Notification Sounds:</h2>
            <span class="settings-label">Enable All Notification Sounds:</span>
            <InputSwitch v-model="enableAllNotificationSounds" class="group-actions-switch" @change="toggleNotificationSound(ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE, )" />
            <span class="settings-label">Enable DM Notifications Sounds:</span>
            <InputSwitch v-model="enableDmNotificationSounds" class="group-actions-switch" :disabled="!enableAllNotificationSounds" @change="toggleNotificationSound(ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE)" />
            <span class="settings-label">Enable General Notification Sounds:</span>
            <InputSwitch v-model="enableGeneralNotificationSounds" class="group-actions-switch" :disabled="!enableAllNotificationSounds" @change="toggleNotificationSound(ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE)" />
            <span class="settings-label">Enable Group Activity Notifications Sounds:</span>
            <InputSwitch v-model="enableGroupActivityNotificationSounds" class="group-actions-switch" :disabled="!enableAllNotificationSounds" @change="toggleNotificationSound(ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE)" />

        </div>
    </div>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';

import {ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE, ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE, ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE,
ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE} from '../../common/constants';
import ChatService from '../../service/ChatService';
import UserService from '../../service/UserService';

export default {
    name: 'SettingsCard',
    emits: ['open-blocked-users-modal'],

    components: { InputSwitch },

    data() {
        return {
            //CONSTANTS
            ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE,
            ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE,
            ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE,
            ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE,

            enableDmMessages: true,
            enableDmNotifications: true,
            enableAllNotificationSounds: true,
            enableDmNotificationSounds: true,
            enableGeneralNotificationSounds: true,
            enableGroupActivityNotificationSounds: true,
            count:0,
        };
    },

    mounted() {
        ChatService.getChatProfile().then((resp) => {
            if (resp.data.status == 'success') {
                this.updateSettingsState(resp.data.settings);
            } else {
                this.$toast.add({ severity: 'error', summary: 'Unexpected error during loading settings', detail: '', life: 3000, group: 'tc' });
            }
        });

        this.enableAllNotificationSounds = this.changeToBoolean(this.$store.getters['users/getSetting'](ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE).value);
        this.enableDmNotificationSounds = this.changeToBoolean(this.$store.getters['users/getSetting'](ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE).value);
        this.enableGeneralNotificationSounds = this.changeToBoolean(this.$store.getters['users/getSetting'](ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE).value);
        this.enableGroupActivityNotificationSounds = this.changeToBoolean(this.$store.getters['users/getSetting'](ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE).value);
    },

    methods: {
        changeToBoolean(value) {
            return value === 'true' ? true : false;
        },

        switchDmMessages() {
            ChatService.updateDmEnabled(this.enableDmMessages).then((resp) => {
                if (resp.data.status == 'success') {
                    this.updateSettingsState(resp.data.settings);

                    this.$toast.add({ severity: 'success', summary: 'Updated settings', detail: '', life: 2000, group: 'tc' });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Unexpected error during updating settings', detail: '', life: 3000, group: 'tc' });
                }
            });
        },

        switchDmNotifications() {
            ChatService.updateDmNotificationsEnabled(this.enableDmNotifications).then((resp) => {
                if (resp.data.status == 'success') {
                    this.updateSettingsState(resp.data.settings);

                    this.$toast.add({ severity: 'success', summary: 'Updated settings', detail: '', life: 2000, group: 'tc' });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Unexpected error during updating settings', detail: '', life: 3000, group: 'tc' });
                }
            });
        },

        toggleNotificationSound(preferenceId) {
            let value = null;

            switch(preferenceId) {
                case ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE:
                    value = this.enableAllNotificationSounds;
                    break;
                case ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE:
                    value = this.enableDmNotificationSounds;
                    break;
                case ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE:
                    value = this.enableGeneralNotificationSounds;
                    break;
                case ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE:
                    value = this.enableGroupActivityNotificationSounds;
                    break;
            }

            UserService.updateUserPreference( preferenceId, value ).then((res) => {
                    if (res.data.status === 'error') {
                        this.$toast.add({ severity: 'error', summary: res.data.message, life: 3000, group: 'center' });
                    } else {
                        const actualValue = value ? 'true' : 'false';
                        const obj = {
                            'id': preferenceId,
                            'value': actualValue
                        };

                        this.$store.dispatch("users/setSetting", obj);
                        console.log(this.$store.getters['users/settingsList']);
                        this.$toast.add({severity: 'success', summary: 'Preference changed!', life: 3000, group: 'center'});
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                })
        },

        updateSettingsState(settings) {
            this.enableDmMessages = settings.dmEnabled;
            this.enableDmNotifications = settings.dmNotificationsEnabled;
        },

        viewBlockedUsers() {
            this.$emit('open-blocked-users-modal');
        },
    },
};
</script>

<style scoped>
.card {
    position: relative;
    display: flex;
    flex-direction: column;
}

.settings-section {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}
.settings-section:last-child {
    margin-bottom: 0;
}

.settings-title {
    margin: 24px 0 0 0;
}
.settings-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #bfbfbf;
}
.settings-label {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
}
.view-blocked-users-button {
    width: fit-content;
    background: none !important;
    border: none;
    padding: 0 !important;
    font-weight: 400;
    font-size: 14px;
    color: #32364e;
    text-decoration: underline;
}
.view-blocked-users-button:hover {
    color: black;
}
</style>