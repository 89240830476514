<template>
    <Avatar class='filler-logo' :label='security.symbol' shape='circle' v-show='!isCompanyLogoLoaded' @click='onClick'/>
    <div class='logo-wrapper' v-show='isCompanyLogoLoaded' @click='onClick'>
        <img :id='imgId' @load='onCompanyLogoLoaded' ref="logoImgTag">
    </div>
</template>

<script>
import Avatar from 'primevue/avatar';

export default {
    name: 'SecurityLogo',
    emits: ['click'],
    components: {
        Avatar
    },

    props: {
        security: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isCompanyLogoLoaded: null
        }
    },
    computed: {
        imgId() {
            return `${this.security.symbol}-company-logo`;
        }
    },

    mounted() {
        this.isCompanyLogoLoaded = false;
        let logo = this.$refs.logoImgTag;//document.getElementById(this.imgId);
        logo.src = this.loadCompanyLogoSrc();
    },
    unmounted() {
        let logo = document.getElementById(this.imgId);
        if (logo) {
            logo.src = '';
        }
        this.isCompanyLogoLoaded = false;
    },

    methods: {
        onClick() {
            this.$emit('click');
        },

        loadCompanyLogoSrc() {
            let logo = document.getElementById(this.imgId);
            const url = this.security.url;

            if (logo) {
                logo.src = '';
            }
            this.isCompanyLogoLoaded = false;


            if (url) {
                return '//logo.clearbit.com/' + url +'?size=80'
            }
        },
        onCompanyLogoLoaded() {
            this.isCompanyLogoLoaded = true;
        }
    }

}
</script>

<style scoped>
.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}
.logo-wrapper img {
    border-radius: 16px;
}

.filler-logo.p-avatar {
    align-self: center;
    font-family: "Trebuchet MS", "Verdana";
    font-weight: 600;
    background-color: #f8f9fa;
    width: 80px;
    height: 50px;
    margin-right: 16px;
}
.logo-wrapper img:hover {
    cursor: pointer;
}

@media (max-width: 760px) {
    .logo-wrapper {
        height: 50px;
        width: 50px;
    }
    .logo-wrapper img {
        height: auto;
        width: 50px;
    }

    .filler-logo.p-avatar {
        width: 100px;
    }
}
</style>