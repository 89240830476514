<template>
    <LoadingSpinner :showLoading="loading" />
    <div class='layout-content' v-show="!loading">
        <div class='tab-div'>
            <ProfileCard :groupContestAwards='groupContestAwards' :headerText='fullName' :subText="`@${username}`" :avatar="avatar" :bioText="bio" :signupDate="signupDate" :userLink="userLink" :notOwnProfile="viewingOtherUserProfile">
            <template #custom>
            <div>Status:&nbsp;<i :class="(online ? 'online-status': '') + ' ' + onlineIcon"  v-tooltip.bottom="onlineTooltip" v-if='!hasAdminModRights()'/>
                 <i  :class="(online ? 'online-status': '') + ' ' + onlineIcon"  v-tooltip.bottom="onlineTooltip" @click='kick()' v-if='hasAdminModRights()'/>
            </div>
            </template>
            <template #actions>
                 
                <Button :class="{'toggle-notes-only-button': true, 'selected': toggleNotesButtonSelected, 'blue-button': true}" 
                class="p-d-md-flex p-d-none" style="padding:0;" v-tooltip.bottom="'Notes Only'" @click="toggleNotesOnly" v-if='!toggleSellerButtonSelected && !toggleSettingsButtonSelected && selectedMenuItem === ACTIVITY_MENU_ITEM'>
                <img src="/assets/images/notes.png" style="width:2rem;"/>
                </Button>
                <template v-if='!showFollow'>
                    <Button v-if="isGroupMonetizationFeatureEnabled()" :class="{'settings-button blue-button': true, 'selected': toggleSellerButtonSelected}" icon='pi pi-dollar' @click='toggleSeller'
                    v-tooltip.bottom="'Payments:  Monetize groups and other resources on the INVRS platform by enabling payments.'"/>
                    <Button :class="{'settings-button blue-button': true, 'selected': toggleSettingsButtonSelected}" icon='pi pi-sliders-v' @click='toggleSettings'
                    v-tooltip.bottom="'Settings'"/>
                    <Button icon='pi pi-pencil' class='blue-button send-dm-button' @click='openEditProfileModal()' v-tooltip.bottom="'Edit Profile'" />
                </template>
                <template v-else>
                    <Button :class="{'send-dm-button blue-button': true}" icon='pi pi-comment' @click='sendInviteToDmOrOpenConversation'
                    v-tooltip.bottom="'Send message'"/>
                    <Button  v-tooltip.bottom="'Report User'" :class="{'send-dm-button blue-button': true}" icon="pi pi-exclamation-triangle" @click='reportUser()' />
                    <FollowButton :followTarget="selectedAnalyst" v-if='selectedAnalyst'/>
                    
                    <Button  :class="{'send-dm-button blue-button': true}" :icon="flagIcon" @click='toggleSpamStatus()' v-if='hasAdminModRights()' v-tooltip.bottom="flagTooltip"/>
                    <Button  :class="{'send-dm-button blue-button': true}" @click='toggleMute()' v-if='hasAdminModRights()' v-tooltip.bottom="muteTooltip">
                        <span class="material-symbols-outlined" style="font-size:16px;">
                        {{muteIcon}}
                        </span>
                    </Button>
                    
                    
                </template>
                <Button  :class="{'send-dm-button blue-button': true}" :icon="switchToAccountIcon" v-tooltip.bottom="switchToAccountTooltip" @click='toggleAccount()' v-if='(hasAdminRights() && viewingOtherUserProfile) || isProxiedUser'/>
                <Button  :class="{'send-dm-button blue-button delete-account-button': true}" @click='confirmDelete()' v-tooltip.bottom="'Delete Account'"  v-if="!viewingOtherUserProfile">
                <span class="material-symbols-outlined" >
                    person_cancel
                    </span>
                </Button>
            </template>
            <template #content>
                <div class='num-stats-row'>
                    <div class='num-notes-posted-block'>
                        <div class='icon-amount'>
                            <i class='pi pi-pencil'></i>
                            <span class='amount'>{{numNotePosts}}</span>
                        </div>
                        <span class='label'>Notes</span>
                    </div>
                    <div class='num-likes-block'>
                        <div class='icon-amount'>
                            <i class='pi pi-thumbs-up'></i>
                            <span class='amount'>{{numReactionsReceived}}</span>
                        </div>
                        <span class='label'>Likes</span>
                    </div>
                    <div class='num-following-block'>
                        <div class='icon-amount'>
                            <i class='pi pi-users'></i>
                            <i class='pi pi-plus-circle'></i>
                            <span class='amount'>{{numFollowing}}</span>
                        </div>
                        <span class='label'>Following</span>
                    </div>
                    <div class='num-followers-block'>
                        <div class='icon-amount'>
                            <i class='pi pi-users'></i>
                            <span class='amount'>{{numFollowers}}</span>
                        </div>
                        <span class='label'>Followers</span>
                    </div>
                </div>

                <div class='followed-tags-section p-d-md-flex p-d-none' v-if='followedTags[0].tags.length > 0'>
                    <span class='followed-tags-header'>Interests:</span>
                    <div class='followed-tags-container'>
                        
                        <div class='followed-tags' id='followedTags'>
                            <div class='followed-tags-row' v-for='(row, index) in followedTags.filter(row => !row.hidden)' :key='row'>
                                <template v-for='(tag, tagIndex) in row.tags' :key='tag'>
                                    <Button class='tag-button' :id="tagButtonId(tag)" :label='tag.name' @click="tagClicked(tag)"/>
                                    <template v-if='(tagIndex === row.tags.length - 1) && (index === followedTags.filter(row => !row.hidden).length - 1) && followedTags.length > 1'>
                                        <Button :label="seeAllButtonLabel" class="see-more-button p-button-text" @click='toggleTags'/>
                                    </template>
                                </template>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </template>
        </ProfileCard>
        <template v-if="toggleSettingsButtonSelected">
            <SettingsCard class="settings-card" @open-blocked-users-modal="openBlockedUsersModal()"/>
        </template>
         <template v-else-if="toggleSellerButtonSelected">
            <SellerCard class="settings-card" />
        </template>
        <template v-else>
            <template v-if="profileFullyLoaded">
                <PillMenuBar :navigationItems="profileMenuItems" headerType="sub" v-if="!isMobile()">
                    <template #left v-if="selectedMenuItem === PORTFOLIO_MENU_ITEM && (accounts.length > 0 && !hasNoSharedAccounts)">
                        <Dropdown class="account-selector" v-model="selectedAccount" optionLabel="name" :options="sharedAccounts" />
                    </template>
                    <template #custom v-if='selectedMenuItem === PORTFOLIO_MENU_ITEM && (accounts.length > 0 && !hasNoSharedAccounts) && !viewingOtherUserProfile'>
                        <Button class='blue-button account-visibility-button' label="Account Visibility" @click="openUpdateAccountVisibilityModal" />
                    </template>
                </PillMenuBar>
                <CarouselMenuBar :menuItems="mobileProfileMenuItems" :numScroll="1" v-else />
            </template>


            <FeedActivityList :loader="loadPosts" ref="activityList" templateForComponent="User Notes" :loadOnMount="false" v-if='selectedMenuItem === ACTIVITY_MENU_ITEM' />
            <template v-else-if="selectedMenuItem === PORTFOLIO_MENU_ITEM">
                <LoadingSpinner :showLoading="!portfolioLoaded" />
                <template v-if='portfolioLoaded'>
                    <div class='connect-button-container' v-if="accounts.length === 0">
                        <Button class='green-button__primary connect-accounts-button' label='Connect your account' @click='connectYourAccount' />
                    </div>
                    <div class='no-shared-accounts-container' v-else-if="accounts.length > 0 && hasNoSharedAccounts">
                        <div class='no-shared-accounts-message'>
                            Share your portfolio with the INVRS community (percentages only, dollar amounts never shown). We never store user credentials and all account connections are encrypted.
                        </div>
                        <Button class='green-button__primary' label='Share your portfolio' @click='openUpdateAccountVisibilityModal' />
                    </div>
                    <Overview :selectedAccountData="selectedAccount" :viewingOwnPortfolio="!viewingOtherUserProfile" v-else-if="accounts.length > 0 && !hasNoSharedAccounts" :key="selectedAccount"/>
                </template>
            </template>
            <FollowerFolloweeSearch :isUser='isUser' :analyst="(selectedAnalyst? selectedAnalyst : $store.state.users.user.analyst)" v-else-if="selectedMenuItem === FF_MENU_ITEM" />
            <FollowedSecuritySearch v-else-if="selectedMenuItem === FOLLOWED_SECURITIES_MENU_ITEM" />
            <UserMotions v-else-if="selectedMenuItem === MOTIONS_MENU_ITEM" />
        </template>
        </div>
    </div>

    <EditProfileModal ref="editProfileModal" :data="{'type': USER_TYPES['USER'], 'fullName': fullName, 'bio': bio, 'username': username, 'avatar': avatar, 'link': userLink}" :linkObject="linkObject"/>
    <UpdateAccountVisibilityModal ref="updateAccountVisibilityModal" :headerText="accounts.length > 0 && hasNoSharedAccounts ? 'Share Accounts' : 'Shared Accounts'" 
        :buttonLabel="accounts.length > 0 && hasNoSharedAccounts ? 'Share' : 'Update'" :accounts="accounts" @get-accounts="getUpdatedAccounts" />
    <BlockedUsersModal ref="blockedUsersModal"/>
    <ConfirmModal ref="confirmKickModal" headerText='Kick User?' confirmButtonLabel='Yes' @confirmed="doKick()"/>
    <ConfirmModal ref="confirmDeleteModal" headerText='Delete Your Account?' confirmButtonLabel='Yes' @confirmed="doDelete()" confirmText="delete">
        <template #content>
             <div style="margin: 16px;font-weight:bold;">Are you sure you want to <span style="text-decoration: underline;">delete</span> your account?<br>
             (This operation cannot be undone.)</div>
             
            </template>

    </ConfirmModal>
</template>

<script>
import ConfirmModal from '../components/modal/ConfirmModal';
import EditProfileModal from '../components/modal/EditProfileModal';
import BlockedUsersModal from '../components/modal/BlockedUsersModal';
import FeedActivityList from '../components/feed/FeedActivityList.vue';
import ProfileCard from '../components/profile/ProfileCard.vue';
import LoadingSpinner from '../components/common/LoadingSpinner';
import SettingsCard from '../components/profile/SettingsCard';
import SellerCard from '../components/profile/SellerCard';
import FollowerFolloweeSearch from '../components/profile/FollowerFolloweeSearch';
import FollowedSecuritySearch from '../components/profile/FollowedSecuritySearch.vue';
import PillMenuBar from '../components/menu/PillMenuBar.vue';
import CarouselMenuBar from '../components/menu/CarouselMenuBar.vue';
import FollowButton from '../components/button/FollowButton.vue';
import UpdateAccountVisibilityModal from '../components/portfolio/profile-portfolio/UpdateAccountVisibilityModal.vue';
import Dropdown from 'primevue/dropdown';
import Overview from '../components/portfolio/profile-portfolio/Overview.vue';
import UserMotions from '../components/profile/UserMotions.vue';

import ChatService from '../service/ChatService';
import FeedService from '../service/FeedService';
import PortfolioService from '../service/PortfolioService';
import AuthService from '../service/AuthService';
import UserService from '../service/UserService';
import UserUtils from '../utilities/UserUtils';
import BrowserUtils from '../utilities/BrowserUtils';
import { USER_TYPES, ACCOUNT_TYPES, CRYPTO_ACCOUNT_TYPE } from '../common/constants';

import { mapState } from 'vuex';
import EventBus from '../event-bus';

const ACTIVITY_MENU_ITEM = 'Activity';
const PORTFOLIO_MENU_ITEM = 'Portfolio';
const FF_MENU_ITEM = 'FF';
const FOLLOWED_SECURITIES_MENU_ITEM = 'Followed Securities';
const MY_MOTIONS_MENU_ITEM = 'My Motions';
const MY_VOTES_MENU_ITEM = 'My Votes';
const MOTIONS_MENU_ITEM = 'Motions';

export default {
    name: 'Profile',
    data() {
        return {
            //CONSTANTS
            USER_TYPES,
            FF_MENU_ITEM,
            PORTFOLIO_MENU_ITEM,
            ACTIVITY_MENU_ITEM,
            FOLLOWED_SECURITIES_MENU_ITEM,
            MY_MOTIONS_MENU_ITEM,
            MY_VOTES_MENU_ITEM,
            MOTIONS_MENU_ITEM,

            selectedAnalyst: null,
            loading: true,
            notesOnly: false,
            tagsOverflowing: false,
            allTags: [],
            containerWidth: null,
            isUser: null,
            profileFullyLoaded: false,
            portfolioLoaded: false,

            // For displaying 
            avatar: null,
            firstName: '',
            middleName: '',
            lastName: '',
            signupDate: '',
            bio: null,
            userLink: null,
            linkObject: {},
            numNotePosts: null,
            numFollowers: null,
            numFollowing: null,
            numReactionsReceived: null,
            username: '',
            followedTags: [{tags: [], hidden: false}],
            accounts: [],
            sharedAccounts: [],
            selectedAccount: null,
            groupContestAwards: null,

            seeMoreTags: false,
            viewingOtherUserProfile: false,
            toggleNotesButtonSelected: false,
            toggleSettingsButtonSelected: false,
            toggleSellerButtonSelected: false,
            online: false,
            selectedMenuItem: null,
            profileMenuItems: [
                    {label: 'Activity', action: this.viewActivity, selected: true},
                    {label: 'Portfolio', action: this.viewPortfolio, selected: false},
                    {label: 'Follows', action: this.viewFollowedAndFollowers, selected: false},
                    {label: 'Securities', action: this.viewFollowedSecurities, selected: false},
                    {label: 'Motions', action: this.viewMotions, selected: false}
            ],
            mobileProfileMenuItems: [
                {
                    label: 'Activity',
                    action: this.viewActivity,
                    selected: true
                },
                {
                    label: 'Follows',
                    action: this.viewFollowedAndFollowers,
                    selected: false
                },
                {
                    label: 'Securities',
                    action: this.viewFollowedSecurities,
                    selected: false
                }
            ],

            userUtils: UserUtils
        }
    },
    components: {
        EditProfileModal, FeedActivityList, LoadingSpinner, ProfileCard, FollowButton, FollowerFolloweeSearch, PillMenuBar, CarouselMenuBar,
        FollowedSecuritySearch, UpdateAccountVisibilityModal, Dropdown, Overview, SettingsCard, BlockedUsersModal,ConfirmModal, UserMotions,
        SellerCard
    },
    watch: {
        $route(newRoute, oldRoute) { //eslint-disable-line
          //console.log("in profile watch with "+ JSON.stringify(newRoute))
          if( newRoute.fullPath.startsWith('/analysts/profile/') || newRoute.fullPath.startsWith('/profile')) {
            this.resetSelection();

            this.viewingOtherUserProfile = false; 
            this.profileMenuItems[0].selected = true;
            this.mobileProfileMenuItems[0].selected = true;
            this.selectedMenuItem = ACTIVITY_MENU_ITEM;
            this.loadProfile();
          }
                
        },

        
        notesOnly(newVal, oldVal) {
            if( newVal != oldVal) {
                this.$refs.activityList?.refreshFeed();
            }
        },

        accounts(value, oldValue) { //eslint-disable-line
            if (this.viewingOtherUserProfile) {
                this.sharedAccounts = value;
            } else {
                this.sharedAccounts = value.filter(acc => acc.sharingLevel === 5);
            }

            if (this.sharedAccounts.length > 0) {
                const accountExists = this.sharedAccounts.filter(acc => acc.investmentAccountId === this.selectedAccount?.investmentAccountId)[0];
                if (!this.selectedAccount || !accountExists) {
                    this.selectedAccount = this.sharedAccounts[0];
                } else {
                    this.selectedAccount = this.changeSharedAccountName(accountExists);
                }
            }
        },

        selectedAccount(val) {
            if (!this.viewingOtherAccount) { // We're able to make trades or add/update position if we're viewing our own portfolio from profile
                this.$store.commit('SET_CURRENTLY_SELECTED_PORTFOLIO', val);
            }
        }
    },

    created() {
        EventBus.off('refresh-profile-portfolio-overview');
        this.EventBus.on('profile-updated', this.loadProfile);
        EventBus.on('toggle-notes-only-button', () => {
            this.notesOnly = !this.notesOnly;
      });
      EventBus.on('refresh-profile-portfolio-overview', this.refreshPortfolioOverview);
        
    },

    mounted() {
        this.viewActivity();
        this.loadProfile();
        
    },
    activated() {
        this.selectedAnalyst = null;
        EventBus.off('edit-profile');
        EventBus.on('edit-profile', this.openEditProfileModal);
    },
    deactivated() {
        this.selectedAnalyst = null;
        this.containerWidth = null;
        this.viewingOtherUserProfile = false;
        this.userLink = null;
        this.linkObject = {};
    },

    
    computed: {
        ...mapState(['users']),
        fullName() {
            let full = this.firstName;
            if( this.middleName ){
                full += " "+this.middleName;
            }
            if( this.lastName) {
                full += " "+this.lastName;
            }
            if( full ) {
                return full;
            }
            else {
                return this.username;
            }
            // if( this.firstName && this.lastName) {
            //     return `${this.firstName} ${this.lastName}`;
            // }
        },

        showFollow() {
            return this.viewingOtherUserProfile;
        },

        seeAllButtonLabel() {
            return this.seeMoreTags ? 'Show Less' : 'Show All';
        },

        hasNoSharedAccounts() {
            return this.accounts.every(acc => acc.sharingLevel === 1);
        },

        flagTooltip() {
            if( this.selectedAnalyst && this.selectedAnalyst.activityStats.numDeletedPosts > 0 ) {
                return "Clear Spammer Status";
            }
            else {
                return "Flag As Spammer";
            }
        },
        flagIcon() {
            if( this.selectedAnalyst && this.selectedAnalyst.activityStats.numDeletedPosts > 0 ) {
                return "pi pi-flag-fill";
            }
            else {
                return "pi pi-flag";
            }
        },
        muteIcon() {
            if( this.selectedAnalyst && this.selectedAnalyst.muted ) {
                return "voice_over_off";
            }
            else {
                return "record_voice_over";
            }
        },
        muteTooltip() {
            if( this.selectedAnalyst && this.selectedAnalyst.muted ) {
                return "Unmute this account.";
            }
            else {
                return "Mute this account. Posts, Motions, Reactions will be ignored.";
            }
        },

        onlineIcon() {
            if( this.online ){
                return "pi pi-circle-fill";
            }
            else {
                return "pi pi-circle";
            }
        },
        onlineTooltip() {
            if( this.online ){
                return "Online" +(this.hasAdminModRights() ? '- Kick' : '');
            }
            else {
                return "Offline";
            }
        },

        switchToAccountIcon() {
            if( this.$store.state.hasProxy ) {
                return "pi pi-eye-slash";
            }
            else {
                return "pi pi-eye";
            }
        },

        switchToAccountTooltip() {
            if( this.$store.state.hasProxy ) {
                return "Switch from Account";
            }
            else {
                return "Switch to Account";
            }
        },

        isProxiedUser() {
            //console.log("isproxied user : "+ this.$store.state.hasProxy);
            return  this.$store.state.hasProxy;
        },

        
    },

    methods: {
        isGroupMonetizationFeatureEnabled(){
            return this.$store.state.featureSettings.groupMonetizationEnabled;
        },


        toggleAccount() {
             if( this.$store.state.hasProxy ) {
                UserService.switchBack(this.$store.state.users.user.sudoer.analyst.analystId).then(
                    (result) => {
                        AuthService.authorizationSuccess(result);
                        this.$router.push({path:'/login'})
                    },
                    (error) => {
                            console.log("error switching account: "+ error);
                            this.$toast.add({ severity: 'error', summary: 'Unexpected error switching back from account see console', detail: '', life: 3000, group: 'tc' });
                    }
                );
             }
             else {
                UserService.switchToAccount(this.selectedAnalyst.analystId).then(
                    (result) => {
                        AuthService.authorizationSuccess(result);
                        this.$router.push({path:'/login'})
                    },
                    (error) => {
                            console.log("error switching account: "+ error);
                            this.$toast.add({ severity: 'error', summary: 'Unexpected error switching account see console', detail: '', life: 3000, group: 'tc' });
                    }
                );

                     
             }
        },

        reportUser() {
            let report = {
                type: 'User',
                userId: this.selectedAnalyst.userId

            }
            EventBus.emit('user-reported', report);
        },


        kick() {
            if( this.selectedAnalyst) {
                this.$refs.confirmKickModal.open();
            }
        },

        doKick() {
            if( this.selectedAnalyst && this.online) {
                UserService.kick(this.selectedAnalyst.analystId).then(resp => {//eslint-disable-line
                    this.getOnlineStatus();
                });
            }
        },

        confirmDelete() {
            if( !this.viewingOtherUserProfile && !this.isProxiedUser ) {
                this.$refs.confirmDeleteModal.open();
            }
        },

        doDelete() {
             if( !this.viewingOtherUserProfile && !this.isProxiedUser ) {
                this.$toast.add({ severity: 'warning', summary: 'Deleting your account...', detail: 'We are deleting  your account details this may take a few minutes.', life: 0, group: 'center' });
                UserService.deleteAccount().then(resp => {//eslint-disable-line
                    EventBus.emit("logout-click");
                });
            }
        },

        getOnlineStatus() {
            if( this.selectedAnalyst) {
                UserService.getPresence(this.selectedAnalyst.analystId).then(resp => {
                    if( resp.data.status == 'success') {
                        this.online = true;
                    }
                    else  {
                        this.online = false;
                    }
                });
            }
            
        },

        isMod(){
            return UserUtils.userIsFeedModerator();
        }, 
        hasAdminModRights() {
                return UserUtils.userIsAdmin() || UserUtils.userIsFeedModerator() || UserUtils.userIsChatModerator();
        },
        hasAdminRights() {
            return UserUtils.userIsAdmin();
        },
        toggleSpamStatus() {
            let isCurrentlySpammer = this.selectedAnalyst.activityStats.numDeletedPosts > 0;
            FeedService.updateSpammerStatus(this.selectedAnalyst.analystId, (isCurrentlySpammer? false : true)).then((resp)=>{
                if( resp.data.status == "success") {
                    if( isCurrentlySpammer ){
                        this.selectedAnalyst.activityStats.numDeletedPosts = 0;
                    }
                    else  {
                        this.selectedAnalyst.activityStats.numDeletedPosts = 1;
                    }
                }
                else {
                    console.log("error updating spammer status "+ JSON.stringify(resp));
                }
            });
        },

        toggleMute() {
            if( this.selectedAnalyst.muted){
                FeedService.unmute(this.selectedAnalyst.userId).then(resp => {
                    if( resp.data.status == "success") {
                        this.selectedAnalyst.muted = false;
                    }
                    else {
                        console.log("error muting account ", resp.data);
                    }
                }).catch(err => {
                    console.log("error muting account", err);
                });
            }
            else {
                FeedService.mute(this.selectedAnalyst.userId).then(resp => {
                    if( resp.data.status == "success") {
                        this.selectedAnalyst['muted'] = true; 
                    }
                    else {
                        console.log("error muting account ", resp.data);
                    }
                }).catch(err => {
                    console.log("error muting account", err);
                });
            }
        },


        resetSelection() {
            if (!this.isMobile()) {
                this.profileMenuItems.forEach((item) => {
                    item.selected = false;
                })
            } else {
                this.mobileProfileMenuItems.forEach((item) => {
                    item.selected = false;
                })
            }
        },

        isActualUser(id) {
            return this.userUtils.isUser(id);
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        tagClicked(tag){
            //console.log("************* ckucjed")
            //console.log("tag clicked" +JSON.stringify(tag));
            this.$router.push('/tag/profile/'+tag.tagId);
        },

        tagButtonId(tag) {
            return `followedTag-${tag.tagId}`;
        },

        async fetchCuratedTags() {
            await FeedService.getCuratedTags().then(resp => {
                const tags = resp.data;

                this.allTags = tags['Investment Categories'].concat(tags['Investment Styles']).concat(tags['Contests']).concat(tags['Sectors']).concat(tags['Industries']);
            });
        },

        sendInviteToDmOrOpenConversation() {
            ChatService.getDMChannelIfExists(this.selectedAnalyst.analystId).then((resp) => {
                if (resp.status == 'success') {
                    if(resp.message == 'no such dm'){
                        // console.log("getDMChannelIfExists", resp, {username:this.selectedAnalyst.name, analystId:this.selectedAnalyst.analystId});
                        this.EventBus.emit('open-create-dm-channel-modal', {username:this.selectedAnalyst.name, analystId:this.selectedAnalyst.analystId});
                    } else {
                        // console.log("getDMChannelIfExists", resp);
                        let channel = resp.channels[0];
                        channel["type"] = "privateMessaging";
                        channel["channelTitle"] = "@"+this.selectedAnalyst.name;
                        this.EventBus.emit('open-dm-channel', channel);
                    }
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Unexpected error during opening chat', detail: '', life: 3000, group: 'tc' });
                }
            });
        },

        openBlockedUsersModal() {
            this.$refs.blockedUsersModal.open();
        },

        toggleSettings() {
            this.toggleSettingsButtonSelected = !this.toggleSettingsButtonSelected;
            
            if (!this.toggleSettingsButtonSelected){
                this.$router.push('/profile');
            } else {
                this.$router.push('/profile/settings');
            }
        },

        toggleSeller() {
            this.toggleSellerButtonSelected = !this.toggleSellerButtonSelected;
            
            if (!this.toggleSellerButtonSelected){
                this.$router.push('/profile');
            } else {
                this.$router.push('/profile/seller');
            }
        },

        toggleNotesOnly() {
			this.toggleNotesButtonSelected = !this.toggleNotesButtonSelected;

			EventBus.emit('toggle-notes-only-button');
		},

        loadPosts(currentFeedItems) {
            
            if( !this.loading ) {
                //console.log("loading more profile posts")
                if( this.selectedAnalyst ) {
                    
                    return FeedService.getProfilePosts(this.selectedAnalyst, 15, currentFeedItems.length, this.notesOnly);
                }
                else {
                   
                    return FeedService.getProfilePosts(this.users.user.analyst, 15, currentFeedItems.length, this.notesOnly);
    
                    
                }
            }
            else {
                return [];
            }
          
            
        },

        /* This function is to see if the tags will overflow on the first row, if it does - We hide any tags in the other rows and they get toggled
         by the "Show All/See Less" button */
        transformTagsToRows() {
            let total = 0; 
            let newTags = [];
            let indexForRow = 0; // This is for slicing subsets of tags from the user's followed tags into specific rows
            let tags = this.followedTags[0].tags;

            // This is the full width of the card minus the padding
            if (!this.containerWidth) {
                this.containerWidth = document.getElementById('followedTags').clientWidth;
            }

            for(let i = 0; i < tags.length; i++) {
                const tag = tags[i];
                const tagWidth = document.getElementById(`followedTag-${tag.tagId}`).offsetWidth; // Includes the margin surrounding the text of the tag button
                const actualTagWidth = tagWidth + 8;

                total += actualTagWidth;
                
                // 55 is the size of the "See More" Button 

                // This splits the tags into respective rows where within each row - It's tags' total width doesn't go over the (card width - (padding + toggle button width))
                if (total > this.containerWidth - 55) {
                    
                    total = actualTagWidth; 

                    newTags.push({'tags': tags.slice(indexForRow, i), 'hidden': newTags.length >= 1});
                    indexForRow = i;
                } 
            }

            /* If there aren't any overflowing tags to begin with (They all fit on the first row), then we just render the tags as how they are. Otherwise, we use the
            new row structure to render the tags */
            if (newTags.length > 0) {
                newTags.push({'tags': tags.slice(indexForRow, tags.length), 'hidden': true})

                this.followedTags = newTags;
            }
        },

        changeSharedAccountName(acc) {
            let obj = Object.assign({}, acc);
            const displayName = obj.nickname ? obj.nickname : obj.name

            obj.name = ACCOUNT_TYPES[acc.accountTypeId] ? `${displayName} - ${ACCOUNT_TYPES[acc.accountTypeId]}` : displayName;

            return obj;
        },

        isNonCryptoAccount(acc) {
            return acc.accountTypeId !== CRYPTO_ACCOUNT_TYPE['ID'];
        },
      
        async loadProfile() {
            this.toggleSettingsButtonSelected = this.$route.path=='/profile/settings';
            this.toggleSellerButtonSelected = this.$route.path=='/profile/seller';

            this.profileMenuItems[1]['hidden'] = false;
            this.profileMenuItems[3]['hidden'] = true;
            this.profileMenuItems[4]['hidden'] = true;
            this.mobileProfileMenuItems[2]['hidden'] = true;
            this.followedTags = [{tags: [], hidden: false}];
            this.accounts = [];
            this.seeMoreTags = false;
            this.loading = true;
            this.portfolioLoaded = false;

            await this.fetchCuratedTags();

            if (this.$route.params && this.$route.params.user && this.$route.params.user !== this.users.user.analyst.name) {
                this.viewingOtherUserProfile = true;
                
                // this.loading = true;
                this.selectedAnalyst = null;
                UserService.getAnalystByUsername(this.$route.params.user).then((resp)=>{

                    if( resp.data.status == "success") {
                        this.selectedAnalyst = resp.data.analyst;
                        this.username = this.selectedAnalyst.name;
                        this.firstName = this.selectedAnalyst.firstName;
                        this.middleName = this.selectedAnalyst.middleName;
                        this.lastName = this.selectedAnalyst.lastName;
                        this.avatar = this.selectedAnalyst.avatar;
                        if(this.selectedAnalyst.createdDate){
                            this.signupDate = new Date(this.selectedAnalyst.createdDate).toLocaleDateString('en-CA');
                        }                        
                        this.bio = this.selectedAnalyst.bio;
                        this.numNotePosts = this.selectedAnalyst.activityStats.numNotePosts;
                        this.numReactionsReceived = this.selectedAnalyst.activityStats.numReactionsReceived;
                        this.numFollowers = this.selectedAnalyst.activityStats.numFollowers;
                        this.numFollowing = this.selectedAnalyst.activityStats.numFollowing;
                        this.isUser = this.isActualUser(this.selectedAnalyst.userId);
                        this.groupContestAwards = this.selectedAnalyst.groupContestAwards;
                        this.$store.commit('SET_SEARCH_BAR_TEXT', this.username);
                        EventBus.emit('populate-search-input');

                        UserService.getUserLink(this.selectedAnalyst.analystId).then(resp => {
                            if (resp.data.status == "success" && resp.data.links.length > 0) {
                                this.userLink = resp.data.links[0].url;
                            } else {
                                this.userLink = null;
                            }
                            this.linkObject = null;
                        });

                        FeedService.getFollowedTags(this.selectedAnalyst.analystId).then(resp => {
                            if( resp.data.status == "success") {
                                
                                this.followedTags[0].tags = this.matchTags(resp.data.followedTags.map(tag => tag.tagId));

                                // For looking at other people's portfolios, we are only concerned with viewing their shared accounts
                                PortfolioService.getSharedAccounts(this.selectedAnalyst.analystId).then(resp => {
                                    if (resp.data.status === 'success') {
                                        if (resp.data.accounts.length > 0) {
                                            this.accounts = resp.data.accounts.map(this.changeSharedAccountName);
                                        } else {
                                            this.profileMenuItems[1]['hidden'] = true;
                                        }
                                    }

                                    this.$nextTick(() => {
                                        this.portfolioLoaded = true;
                                        this.loading = false;
                                        this.profileFullyLoaded = true;
                                        this.$refs.activityList?.refreshFeed();

                                        this.viewPortfolioAccount();

                                         // This waits for the DOM to be fully updated in order to get the width of the card container and the width of each tag 
                                        if (this.followedTags[0].tags.length > 0) {
                                            this.$nextTick(() => {
                                                this.transformTagsToRows();
                                            })
                                        }
                                    })
                                })
                            }
                            else {
                                console.log("error getting followed tags :" + JSON.stringify(resp.data));
                            }

                            
                        })
                        //if( this.hasAdminModRights() ){
                            this.getOnlineStatus();
                        //}
                        EventBus.emit('track-view-profile', this.selectedAnalyst)
                        
                    }
                });

                
            } else { // If viewing your own profile
                this.getOwnProfile();
                
            }
        }, 
        getOwnProfile() {
            const user = this.$store.getters['users/getUser'];
            this.online = true;
            this.$store.commit('SET_SEARCH_BAR_TEXT', user.analyst.name);
            EventBus.emit('populate-search-input');

            this.selectedAnalyst = null;

            this.profileMenuItems[3]['hidden'] = false;
            this.profileMenuItems[4]['hidden'] = false;
            this.mobileProfileMenuItems[2]['hidden'] = false;

            if (!(PortfolioService.portfoliosSettingsEnabled() && PortfolioService.portfoliosFeatureEnabled())) {
                this.profileMenuItems[1]['hidden'] = true;
            }
           
            this.username = user.analyst.name;
            this.firstName = user.analyst.firstName;
            this.middleName = user.analyst.middleName;
            this.lastName = user.analyst.lastName;
            this.avatar = user.analyst.avatar;
            this.bio = user.analyst.bio;
            this.signupDate = new Date(user.signupDate).toLocaleDateString('en-CA');
            this.numNotePosts = user.analyst.activityStats.numNotePosts;
            this.numReactionsReceived = user.analyst.activityStats.numReactionsReceived;
            this.numFollowers = user.analyst.activityStats.numFollowers;
            this.numFollowing = user.analyst.activityStats.numFollowing;
            this.isUser = this.isActualUser(user.analyst.userId);
            this.groupContestAwards = user.analyst.groupContestAwards;
            this.followedTags[0].tags = this.matchTags(Object.keys(user.analyst.followed.followedTags));

            UserService.getUserLink(user.analyst.analystId).then(resp => {
                if (resp.data.status == "success" && resp.data.links.length > 0) {
                    this.linkObject = resp.data.links[0];
                    this.userLink = this.linkObject.url;
                } else {
                    this.linkObject = null;
                    this.userLink = null;
                }
            });

            PortfolioService.getListOfAccounts().then(resp => {
                if (resp.data.status === 'success') {
                    const nonCryptoAccounts = resp.data.accounts.filter(this.isNonCryptoAccount);

                    this.accounts = nonCryptoAccounts.map(this.changeSharedAccountName);
                }

                this.$nextTick(() => {
                    this.portfolioLoaded = true;
                    this.loading = false;
                    this.profileFullyLoaded = true;
                    this.$refs.activityList?.refreshFeed();

                    this.viewPortfolioAccount();

                    // This waits for the DOM to be fully updated in order to get the width of the card container and the width of each tag

                    if (this.followedTags[0].tags.length > 0) {
                        this.$nextTick(() => {
                            this.transformTagsToRows();
                        })
                    }
                })
            })
        }, 

        viewPortfolioAccount(){
            if(!this.isMobile() && this.$route.query.contest) {
                const account = this.sharedAccounts.find(acc=>acc.contestId==this.$route.query.contest);
                
                if(account){
                    this.selectedAccount = account;
                }
                this.viewPortfolio();
                this.resetSelection();
                this.profileMenuItems[1]['selected'] = true;
            }
        },

        openEditProfileModal() {
            this.$refs.editProfileModal.open();
        },
        openUpdateAccountVisibilityModal() {
            this.$refs.updateAccountVisibilityModal.open();
        },
        toggleTags() {

            for(let i = 0; i < this.followedTags.length; i++) {
                if (i > 0) {
                    this.followedTags[i].hidden = this.seeMoreTags;
                }
            }

            this.seeMoreTags = !this.seeMoreTags;


        },

         // ARRAY MAP, REDUCE, FILTER METHODS

        matchTags(followedTagIds) {
            return followedTagIds.reduce((total, id) => {
                const matchedTag = this.allTags.filter((tag) => {

                    return Number(id) === tag.tagId;
                });
                if( matchedTag && matchedTag[0] != null){
                    total.push(matchedTag[0])
                }

                return total;
            }, []);
            
        },

        addHiddenParameterToTag(tag) {
            return {...tag, hidden: false};
        },

        async getConnectionParams() {
            const params = await PortfolioService.getConnectionParams();
            if (params.data.status === "error") {
                this.$toast.add({ severity: 'error', summary: "We are unable to connect to portfolios currently. If the problem persists please contact support.", life: 5000, group: 'center' });
                return 'error';
            }
            return params.data.params.url;
        },

        async connectYourAccount() {
            // open plaid overlay
            // this.plaidHandler.open();

            const redirectUrl = await this.getConnectionParams();
            if (redirectUrl === 'error') {
                return;
            } 
            window.location.href = redirectUrl;
        },

        getUpdatedAccounts(updated) {
            this.portfolioLoaded = false;
            let updateRequests = [];

            this.accounts.forEach((acc) => {
                updated.forEach((updateAcc) => {
                    if (acc.investmentAccountId === updateAcc.investmentAccountId) {
                        if (acc.sharingLevel !== updateAcc.sharingLevel) {
                            updateRequests.push(PortfolioService.shareAccount(acc.investmentAccountId, updateAcc.sharingLevel));

                            if(updateAcc.sharingLevel == 5) {
                                EventBus.emit('track-sharing-portfolio-on-personal-profile');
                            }
                        }
                    }
                })
            });

            Promise.allSettled(updateRequests).then((values) => {
                
                const checkStatus = (value) => {
                   return value.value.data.status === 'success'; 
                }
                const updateSuccessful = values.every(value => checkStatus(value));

                if (updateSuccessful) {
                    this.$toast.add({ severity: 'success', summary: "Successfully updated selected accounts!", life: 3000, group: 'center' });
                    EventBus.emit('get-accounts');
                } else {
                    const someUpdatesSuccessful = values.some(value => checkStatus(value));

                    if (someUpdatesSuccessful) {
                        this.$toast.add({ severity: 'error', summary: "Some acounts were successfully updated, please try again for the rest.", life: 3000, group: 'center' });
                        EventBus.emit('get-accounts');
                    } else {
                        this.$toast.add({ severity: 'error', summary: "Failed to update selected accounts, please try again.", life: 3000, group: 'center' });
                    }
                }

                PortfolioService.getListOfAccounts().then((resp) => {
                    if (resp.data.status === 'success') {
                        const nonCryptoAccounts = resp.data.accounts.filter(this.isNonCryptoAccount);

                        this.accounts = nonCryptoAccounts.map(this.changeSharedAccountName);
                    }

                    this.portfolioLoaded = true;
                });
            })
        },

        refreshPortfolioOverview() {
            // We don't want to refresh if we're not looking at our own profile since we don't own other user accounts
            if (!this.viewingOtherUserProfile) {
                this.portfolioLoaded = false;
                PortfolioService.getListOfAccounts().then((resp) => {
                    if (resp.data.status === 'success') {
                        const nonCryptoAccounts = resp.data.accounts.filter(this.isNonCryptoAccount);

                        this.accounts = nonCryptoAccounts.map(this.changeSharedAccountName);
                    }

                    this.portfolioLoaded = true;
                });
            }
        },

        // SUB PILL MENU BAR METHODS
        viewNotes() {

        },
        viewActivity() {
            this.toggleNotesButtonSelected = false;
            this.selectedMenuItem = ACTIVITY_MENU_ITEM;

            this.$nextTick(() => {
                this.$refs.activityList?.refreshFeed();
            })
        },
        viewPortfolio() {
            this.selectedMenuItem = PORTFOLIO_MENU_ITEM;
        },
        viewFollowedAndFollowers() {
            this.selectedMenuItem = FF_MENU_ITEM;
        },
        viewFollowedSecurities() {
            this.selectedMenuItem = FOLLOWED_SECURITIES_MENU_ITEM;
        },
        viewMyMotions() {
            this.selectedMenuItem = MY_MOTIONS_MENU_ITEM;
        },
        viewMyVotes() {
            this.selectedMenuItem = MY_VOTES_MENU_ITEM;
        },
        viewMotions() {
            this.selectedMenuItem = MOTIONS_MENU_ITEM;
        }
    }
}
</script>

<style scoped>

*:not(.pi, .material-icons-outlined, .material-symbols-outlined), ::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.delete-account-button {
    padding-left:8px !important;
    padding-right:8px !important;
}
.delete-account-button > span{
    
    font-size: 16px;
}

.red-button {
    color: #FFFFFF;
	background: #000;
	border: 2px solid #000;
}

.red-button:hover {
    color: #FFFFFF;
	background: #E63E3E;
	border: 2px solid #E63E3E;
}

.layout-content {
    padding: 8px 0px;
}

.edit-profile-button {
    border-style: solid;
    border-width: 1px;
    height: 25px;
    padding: 16px;
    border-radius: 10px;
}

::v-deep(.follow-button) {
    height: 30px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
}

::v-deep(.settings-card) {
    margin-bottom: 50px !important;
}
/* .top-row .user-bio .row .edit-profile-button {
    margin-left: auto;
} */

.num-stats-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 0px;
}

.num-stats-row > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 65px;
}
.num-stats-row > div .icon-amount {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}
.num-stats-row > div .icon-amount .amount {
    font-size: 20px;
    color: black;
}
.num-stats-row > div .label {
    width: 100%;
    font-size: 16px;
    text-align: left;
    color: #a6a6a6;
}
::v-deep(.num-stats-row > div .icon-amount .pi) {
    color: #33CC99;
}
::v-deep(.num-stats-row > div .icon-amount .pi-pencil),
::v-deep(.num-stats-row > div .icon-amount .pi-thumbs-up) {
    font-size: 25px;
}
::v-deep(.num-stats-row > div .icon-amount .pi-users) {
    font-size: 30px;
}

.num-following-block {
    position: relative;
}
.num-following-block .pi-plus-circle {
    position: absolute;
    left: 28px;
    top: 0px;
    font-size: 12px;
}

.tag-button {
    font-size: 14px;
    background: #F2F4FA;
    color: #32364e;
    border: none;
    border-radius: 50px;
    justify-content: center;
    /* pointer-events: none; */

}

/* .tag-button.selected,
.tag-button:hover {
    color: #ffffff;
    background: #33cc99;
}
.tag-button:enabled:focus {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-color: #33cc99;
} */
.followed-tags-section {
    display:flex;
    flex-direction: column;
    margin-top: 24px;
}
.followed-tags-section .followed-tags-header {
    font-size: 20px;
    color: #32364e;
    font-weight: bold;
    margin-bottom: 8px;
}
.followed-tags-section .followed-tags-container {
    display: flex;
    align-items: center;
}
.followed-tags-section .followed-tags-container .followed-tags {
    flex: 0 1 100%;
}
.followed-tags-section .followed-tags-container .followed-tags .followed-tags-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
}
.followed-tags-section .followed-tags-container .followed-tags .followed-tags-row:last-of-type {
    margin-bottom: 0px;
}
.followed-tags-section  .followed-tags-container .followed-tags .followed-tags-row .tag-button {
    margin-right: 8px;
}
.followed-tags-section  .followed-tags-container .followed-tags .followed-tags-row .tag-button:last-child {
    margin-right: 0px;
}

.online-status{
    color:#33CC99;
}

::v-deep(.send-dm-button),
::v-deep(.settings-button),
::v-deep(.toggle-notes-only-button) {
	border-radius: 16px;
	padding: 6px 16px;
	border-style: solid;
    border-width: 1px;
    margin-right: 8px;
}
::v-deep(.settings-button.selected),
::v-deep(.toggle-notes-only-button.selected) {
        background: #32364e;
        color: #FFFFFF;
        border: 1px solid #32364e;
 }


 .see-more-button:enabled:hover {
    background: none;
    border: none;
    color: #33cc99;
}
.see-more-button {
    background: none;
    border: none;
    color: #32364e;
    font-weight: bold;
    padding: 0px;
}
.see-more-button:enabled:focus {
    box-shadow: none;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364e;
    border-radius: 50px;
    padding: 1px 4px 1px 8px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}

.connect-button-container {
    width: 100%;
    text-align: center;
    margin-top: 65px;
}
.connect-accounts-button {
    font-size: 16px;
    border-radius: 8px;
    padding: 8px 16px;
}

.no-shared-accounts-container {
    margin-top: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-shared-accounts-message {
    margin-bottom: 25px;
    font-size: 16px;
    color: #BFBFBF;
    text-align: center;
}

.account-visibility-button {
    border-radius: 50px;
}

.account-selector {
    max-width: 315px;
}


 @media screen and (max-width: 1600px) and (min-width: 1200px) {
    .account-selector {
        max-width: 240px;
    }
}
 @media screen and (max-width: 1200px) and (min-width: 760px) {
    .account-selector {
        max-width: 190px;
    }
}

@media (max-width: 760px) {
    h1{
        font-size: 1.25rem !important;
    }

    .card {
        border-radius: 0;
        padding: 16px;
        margin-bottom: 0px; 
        box-shadow: none;
    }
    .num-stats-row {
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
    }

    .user-action-button {
        height: 25px;
        padding: 8px;
        border-radius: 6px;
        font-size: 0.8rem;
    }
    .icon-amount {
        justify-content: flex-start !important;
    }
    .icon-amount i {
        font-size: 20px !important;
    }
    .icon-amount .amount {
        margin-left: 8px;
        font-size: 18px !important;
    } 

    .num-following-block .pi-plus-circle {
        left: 17px;
        font-size: 8px !important;
    }
}
</style>